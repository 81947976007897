// NotificationIcon.jsx
import React from 'react';
import './NotificationList.css';
import { FaBell } from 'react-icons/fa';
import { PersistentNotificationSystemModel, ValueLabelString } from '@common/modelDefinition';
import useStore from '@data/state/zustand';
// import translate from '@data/translations';
// import ConfirmButtons from '@components/buttons/confirmButtons';
import ContextMenu from '@components/ContextMenu';

import translate from '@data/translations';
import ConfirmButtons from '@components/buttons/confirmButtons';
import {
    archiveNotification,
    getContextMenuOptions,
    getReplayNotificationTemplate,
    handleReplySubmit,
    markAsReadNotification,
} from './controller';

interface NotificationListProps {
    notificationCount: number;
    setShowNotifications: Function;
    showNotifications: boolean;
}

export enum SeverityEnum {
    'Low',
    'Medium',
    'High',
}

type GroupedNotifications = Record<string, Record<string, PersistentNotificationSystemModel[]>>;
const NotificationList = (props: NotificationListProps) => {
    const notificationRef = React.useRef<HTMLDivElement>(null);
    const {  setShowNotifications, showNotifications } = props;
    const loggedUser = useStore((appState) => appState.authTokenInfo?.user);

    // Sakrij obavijest (postavi isActive na false)
    const persistentNotificationSystems = useStore((appState) => appState.model.PersistentNotificationSystem);
    const [showByTitles, setShowByTitles] = React.useState([] as string[]);
    const [contextMenuIndex, setContextMenuIndex] = React.useState<number | null>(null);
    const [points, setPoints] = React.useState<any>(null);
    const [activeTab, setActiveTab] = React.useState<ValueLabelString>({ value: 'active', label: 'Active' });
    const [replyInputs, setReplyInputs] = React.useState<PersistentNotificationSystemModel | null>(null);
    const [replyInputsAll, setReplyInputsAll] = React.useState<PersistentNotificationSystemModel | null>(null);

    React.useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (
                notificationRef.current &&
                !notificationRef.current.contains(event.target as Node) &&
                showNotifications
            ) {
                setShowNotifications(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [showNotifications, setShowNotifications]);

    // Prikaz samo aktivnih obavijesti
    const notificationsToShow = persistentNotificationSystems.filter((notif: PersistentNotificationSystemModel) => {
        const readByUserUUids = notif.readByUserUuids
            ? typeof notif.readByUserUuids === 'string'
                ? JSON.parse(notif.readByUserUuids)
                : notif.readByUserUuids
            : [];
        switch (activeTab.value) {
            case 'active':
                return notif.isActive && !readByUserUUids.includes(loggedUser.uuid);
            case 'seen':
                return notif.isActive && readByUserUUids.includes(loggedUser.uuid);
            case 'archive':
                return !notif.isActive;
            default:
                return true;
        }
    });

    const activeNotifications = persistentNotificationSystems.filter((notif: PersistentNotificationSystemModel) => {
        const readByUserUUids = notif.readByUserUuids
            ? typeof notif.readByUserUuids === 'string'
                ? JSON.parse(notif.readByUserUuids)
                : notif.readByUserUuids
            : [];
        if (notif.isActive && !readByUserUUids.includes(loggedUser.uuid)) {
            return notif;
        } else {
            return null;
        }
    });
    const sortedNotificationsToShow = notificationsToShow?.sort(
        (a: PersistentNotificationSystemModel, b: PersistentNotificationSystemModel) => b.severityEnum - a.severityEnum
    );
    // Grupiraj obavijesti po severityu
    const groupedNotifications: GroupedNotifications = sortedNotificationsToShow.reduce(
        (acc: any, notif: PersistentNotificationSystemModel) => {
            const { severityEnum, title } = notif;

            if (!acc[SeverityEnum[severityEnum]]) {
                acc[SeverityEnum[severityEnum]] = {};
            }

            if (!acc[SeverityEnum[severityEnum]][title]) {
                acc[SeverityEnum[severityEnum]][title] = [];
            }

            acc[SeverityEnum[severityEnum]][title].push(notif);
            return acc;
        },
        {} as GroupedNotifications
    );

    // if (persistentNotificationSystems.length === 0 || !persistentNotificationSystems) {
    //     return null;
    // }

    const flattenedNotifications = Object.entries(groupedNotifications).flatMap(([severity, titles]) =>
        Object.entries(titles).flatMap(([title, messages]) => {
            if (!showByTitles.includes(title)) {
                return [{ type: 'title', value: title, severity }];
            } else {
                return [
                    { type: 'title', value: title, severity },
                    ...messages.map((notif) => ({ type: 'message', value: notif.key, severity })),
                ];
            }
        })
    );

    return !showNotifications ? (
        <div ref={notificationRef} className="">
            <div className="notification-container" onClick={() => setShowNotifications(true)}>
                <FaBell className="notification-icon" />
                {activeNotifications.length > 0 && (
                    <span className="notification-badge">{activeNotifications.length}</span>
                )}
            </div>
        </div>
    ) : (
        <div ref={notificationRef}>
            <div className="notification-container" onClick={() => setShowNotifications(false)}>
                <FaBell className="notification-icon" />
                {activeNotifications.length > 0 && (
                    <span className="notification-badge">{activeNotifications.length}</span>
                )}
            </div>
            <div className="notifications-container">
                <div className="mt-16p mb-16p" style={{ width: '75%' }}>
                    <h1 className="notifications-h1">{translate('Notifications')}</h1>
                </div>
                <div className="tabs-container">
                    {[
                        { value: 'active', label: 'Active' },
                        { value: 'seen', label: 'Seen' },
                        { value: 'archive', label: 'Archive' },
                    ].map((tab) => (
                        <div
                            key={tab.value}
                            className={`tab-item ${activeTab.value === tab.value ? 'active' : ''}`}
                            onClick={() => setActiveTab(tab)}
                        >
                            {translate(tab.label)}
                        </div>
                    ))}
                </div>

                {Object.entries(groupedNotifications).map(([severity, titles]) => (
                    <div key={severity} className="severity-group">
                        {Object.entries(titles).map(([title, notifs]) => {
                            const headerOptions = getContextMenuOptions(notifs, loggedUser, activeTab, true);
                            const _index = flattenedNotifications.findIndex(
                                (notif) =>
                                    notif.type === 'title' && notif.value === title && notif.severity === severity
                            );

                            return (
                                <div key={title} className="title-group">
                                    <div
                                        onClick={() => {
                                            if (showByTitles.includes(title)) {
                                                const newShowByTitles = showByTitles.filter((s) => s !== title);
                                                setShowByTitles(newShowByTitles);
                                                if (replyInputs && replyInputs.title === title) {
                                                    setReplyInputs(null);
                                                }
                                                if (replyInputsAll && replyInputsAll.title === title) {
                                                    setReplyInputsAll(null);
                                                }
                                            } else {
                                                setShowByTitles([...showByTitles, title]);
                                            }
                                        }}
                                        className={`notification ${severity.toLowerCase()} notification-title`}
                                    >
                                        <p>{translate(title)}</p>
                                        {!replyInputs && !replyInputsAll ? (
                                            <ContextMenu
                                                options={headerOptions}
                                                style={{ width: '280px' }}
                                                position={_index}
                                                contextMenuIndex={contextMenuIndex} // Right-click context menu + Select row css
                                                setContextMenuIndex={setContextMenuIndex}
                                                points={points} // Right-click context menu
                                                setPoints={setPoints}
                                                onSelect={async (option: string) => {
                                                    if (option === 'Mark as seen' || option === 'Mark as unseen') {
                                                        await markAsReadNotification(notifs, loggedUser);
                                                    } else if (option === 'Archive') {
                                                        await archiveNotification(notifs);
                                                    } else if (option === 'Reply to all' && notifs[0]) {
                                                        if (!showByTitles.includes(title)) {
                                                            const newShowByTitles = [...showByTitles, title];
                                                            setShowByTitles(newShowByTitles);
                                                        }
                                                        const newReply = getReplayNotificationTemplate(
                                                            notifs[notifs.length-1],
                                                            loggedUser, 
                                                            true
                                                        );
                                                        setReplyInputs(newReply);
                                                    }
                                                }}
                                            />
                                        ) : null}
                                    </div>
                                    {showByTitles.includes(title)
                                        ? notifs.map((notif) => {

                                              if (!notif.id) {
                                                  return null;
                                              }
                                              const options = getContextMenuOptions([notif], loggedUser, activeTab);
                                              const __index = flattenedNotifications.findIndex(
                                                  (n) =>
                                                      n.type === 'message' &&
                                                      n.value === notif.key &&
                                                      n.severity === severity
                                              );
                                              return (
                                                  <div key={notif.id}>
                                                      <div
                                                          className={`notification ${SeverityEnum[
                                                              notif.severityEnum
                                                          ].toLowerCase()}`}
                                                      >
                                                          <div className="notification-content">
                                                              <div className="notification-meta">
                                                                  <span className="notification-user">
                                                                      {notif.operatorName}
                                                                  </span>
                                                                  <span className="notification-user">
                                                                      {notif.targetUserName}
                                                                  </span>
                                                                  <span className="notification-time">
                                                                      {new Date(notif.timestamp).toLocaleDateString()}{' '}
                                                                      {/* Prilagodite format datuma po potrebi */}
                                                                      {new Date(notif.timestamp).toLocaleTimeString(
                                                                          [],
                                                                          {
                                                                              hour: '2-digit',
                                                                              minute: '2-digit',
                                                                          }
                                                                      )}
                                                                  </span>
                                                              </div>
                                                              <div className="error-container">{translate(notif.message)}</div>
                                                          </div>
                                                          {!replyInputs && !replyInputsAll? (
                                                              <ContextMenu
                                                                  options={options}
                                                                  style={{ width: '280px' }}
                                                                  position={__index}
                                                                  contextMenuIndex={contextMenuIndex} // Right-click context menu + Select row css
                                                                  setContextMenuIndex={setContextMenuIndex}
                                                                  points={points} // Right-click context menu
                                                                  setPoints={setPoints}
                                                                  onSelect={async (option: string) => {
                                                                      if (notif && notif.id) {
                                                                          if (
                                                                              option === 'Mark as seen' ||
                                                                              option === 'Mark as unseen'
                                                                          ) {
                                                                              await markAsReadNotification(
                                                                                  [notif],
                                                                                  loggedUser
                                                                              );
                                                                          } else if (option === 'Archive') {
                                                                              await archiveNotification([notif]);
                                                                          } else if (option === 'Reply') {
                                                                              const newReply =
                                                                                  getReplayNotificationTemplate(
                                                                                      notif,
                                                                                      loggedUser
                                                                                  );
                                                                              setReplyInputs(newReply);
                                                                          }
                                                                      }
                                                                  }}
                                                              />
                                                          ) : null}
                                                      </div>

                                                      {replyInputs && replyInputs.id === notif.id ? (
                                                          <div className="reply-container">
                                                              <textarea
                                                                  autoFocus  
                                                                  value={replyInputs.message}
                                                                  onChange={(e) => {
                                                                      const newReply = { ...replyInputs };
                                                                      newReply.message = e.target.value;
                                                                      setReplyInputs(newReply);
                                                                  }}
                                                                  placeholder="Enter your reply..."
                                                                  className="reply-input"
                                                                  onKeyDown={async (e) => {
                                                                      if (e.key === 'Enter' && !e.shiftKey) {
                                                                          e.preventDefault();
                                                                          await handleReplySubmit(replyInputs);
                                                                          setReplyInputs(null);
                                                                      }
                                                                  }}
                                                              />
                                                              <div className="d-flex mt-8p">
                                                                  <ConfirmButtons
                                                                      onConfirm={async () => {
                                                                          await handleReplySubmit(replyInputs);
                                                                          setReplyInputs(null);
                                                                      }}
                                                                      onCancel={() => setReplyInputs(null)}
                                                                      confirmText="Send"
                                                                  />
                                                              </div>
                                                          </div>
                                                      ) : null}
                                                  </div>
                                              );
                                          })
                                        : null}
                                    {replyInputsAll && replyInputsAll.title === title ? (
                                        <div className="reply-container">
                                            <textarea
                                                autoFocus
                                                value={replyInputsAll.message}
                                                onChange={(e) => {
                                                    const newReply = { ...replyInputsAll };
                                                    newReply.message = e.target.value;
                                                    setReplyInputs(newReply);
                                                }}
                                                placeholder={translate("Reply")}
                                                className="reply-input"
                                                onKeyDown={async (e) => {
                                                    if (e.key === 'Enter' && !e.shiftKey) {
                                                        e.preventDefault();
                                                        await handleReplySubmit(replyInputsAll);
                                                        setReplyInputs(null);
                                                    }
                                                }}
                                            />
                                            <div className="d-flex mt-8p">
                                                <ConfirmButtons
                                                    onConfirm={async () => {
                                                        await handleReplySubmit(replyInputsAll);
                                                        setReplyInputs(null);
                                                    }}
                                                    onCancel={() => setReplyInputs(null)}
                                                    confirmText="Send"
                                                />
                                            </div>
                                        </div>
                                    ) : null}
                                </div>
                            );
                        })}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default NotificationList;
