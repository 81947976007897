import React, { useState, useEffect } from 'react';
import PermissionCheck from '../PermissionCheck';
import FeatureFlag from '../FeatureFlag';
import cloudMenuItems from './menuItems2';
import onPremMenu from './onPremMenu';
import chevronUp from '../../assets/custom/icons/chevron_up.svg';
import chevronDown from '../../assets/custom/icons/chevron_down.svg';
import chevronLeft from '../../assets/custom/icons/chevron_left.svg';
import chevronRight from '../../assets/custom/icons/chevron_right.svg';
import { ReactComponent as Logo } from '../../assets/custom/logo/logo.svg';
import { ReactComponent as LogoWithoutText } from '../../assets/custom/logo/hospitalLogo.svg';
import closeIcn from '../../assets/custom/icons/ex.svg';
import { getQuery } from '@pages/newReservations/utils';
import useStore from '@data/state/zustand';

function SideMenu(props) {
    const loggedUser = useStore((appState) => appState.authTokenInfo?.user);
    const licence = useStore((appState) => appState.licence);
    const posTerminals = useStore((appState) => appState.model.PosTerminal);
    const posDisplayConfigs = useStore((appState) => appState.model.PosDisplayConfig);
    const appContext = useStore.getState().appContext;

    let isOnPrem = false;
    if (licence?.licence?.decodedLicence?.pack?.features?.includes('onPrem')) {
        isOnPrem = true;
    }

    let menuItemsToShow = isOnPrem ? onPremMenu : cloudMenuItems;
    if(props.isSoftwareBlockedDueToLicenseExpiry){
        menuItemsToShow = {}
        menuItemsToShow["logout"] = cloudMenuItems["logout"]
    }

    const menuItems = menuItemsToShow
    const [selectedMenuItems, setSelectedMenuItems] = useState(null);
    const [showMenu, setShowMenu] = useState(true);
    // const [isMobileView, setIsMobileView] = useState(false)
    const isMobileView = props.isMobileView ? props.isMobileView : false;

    const query = getQuery(props.location);
    const resId = query.resId && query.resId !== 'undefined' ? query.resId : undefined; // može biti groupInfoUuid ili reservationId
    const path = query.path && query.path !== 'undefined' ? query.path : undefined; // može biti edit ili undefined
    const queryStringRes = resId ? (resId !== 'undefined' ? `?resId=${resId}` : '') : '';
    const queryStringPath = path ? (path !== 'undefined' ? `&path=edit` : '') : '';

    const toggleMenu = () => {
        setShowMenu(!showMenu);

        if (showMenu === false) {
            selectItemOnRefresh();
        } else {
            setSelectedMenuItems(null);
        }
        if (props.showSideMenuProp) {
            props.showSideMenuProp(false);
        }
    };

    const selectMainMenuItem = (menuItem) => {
        if (menuItem.whenClicked) {
            menuItem.whenClicked();
        }

        if (menuItem.link) {
            props.history.push(menuItem.link);
        }
        setSelectedMenuItems(selectedMenuItems === menuItem.id ? null : menuItem.id);
        let allSelectedMenuItems = selectedMenuItems ? [...selectedMenuItems] : [];

        const index = allSelectedMenuItems.indexOf(menuItem);
        if (index > -1) {
            // only splice array when item is found
            allSelectedMenuItems.splice(index, 1); // 2nd parameter means remove one item only
        } else {
            allSelectedMenuItems.push(menuItem);
        }
        setSelectedMenuItems(allSelectedMenuItems);

        if (!showMenu && !isMobileView) {
            setShowMenu(true);
        }
    };

    const selectSubItem = (subItem) => {
        if (subItem.whenClicked) {
            subItem.whenClicked();
        }
        if (subItem.link) {
            let selectedPosTerminalId = null;
            if (loggedUser?.uuid && posDisplayConfigs) {
                const posDisplayConfig = posDisplayConfigs.find((p) =>
                    p.associatedUserUuids?.includes(loggedUser?.uuid)
                );
                if (posDisplayConfig?.associatedTerminalIds) {
                    const targetPosTerminal = posTerminals?.find((p) => {
                        if (p.terminalType === 0 && posDisplayConfig.associatedTerminalIds.includes(Number(p.id))) {
                            return p; //Pronađen prvi dozvoljen pos terminal
                        } else {
                            return null;
                        }
                    });
                    if (targetPosTerminal) {
                        selectedPosTerminalId = targetPosTerminal.id;
                    }
                }
            }
            const targetLink = subItem.link.replace(
                ':posTerminalId',
                `${selectedPosTerminalId}${queryStringRes}${queryStringPath}`
            );
            props.history.push(targetLink);
        }
    };

    const selectItemOnRefresh = () => {
        Object.keys(menuItems).forEach((key) => {
            if (menuItems[key].items) {
                if (props.history.location.pathname === '/' && !props.history.location.search) {
                            setSelectedMenuItems([menuItems['dashboard']]);
                } else {
                    menuItems[key].items.forEach((subItem) => {
                        if (
                            subItem.link === props.history.location.pathname ||
                            subItem.link.substring(1) === props.history.location.search
                        ) {
                            // this.setState({ selectedMenuItems: menuItems[key].id });
                            setSelectedMenuItems([menuItems[key]]);
                        }
                    });
                }
            }
        });
    };

    function addListenerForOutsideMenuClick(event) {
        const target = document.querySelector('#hamburger-mobile');

        const withinBoundaries = event.composedPath().includes(target);
        if (!withinBoundaries && isMobileView) {
            if (props.showSideMenuProp) {
                props.showSideMenuProp(false);
            }
        }
    }

    function checkIfLinkAndUrlMatchWithoutParams(linkFromSidebarConfig, linkFromUrl) {
        if (!linkFromUrl) {
            return false;
        }
        // const linkFromSidebarConfig = "/konj/:aaa/konj/aaa"
        // const linkFromUrl = "/konj/21212/konj/aaa"

        const splittedLink1 = linkFromSidebarConfig.split('/');
        const splittedLink2 = linkFromUrl.split('/');

        let newStr1 = '';
        let newStr2 = '';
        splittedLink1.forEach((itm, ind) => {
            if (!itm.includes(':')) {
                newStr1 += itm;
                newStr2 += splittedLink2[ind];
            }
        });

        if (newStr1 === newStr2 && splittedLink1.length === splittedLink2.length) {
            return true;
        }

        return false;
    }

    useEffect(() => {
        if (window.innerWidth <= 768) {
            setShowMenu(false);
            document.addEventListener('click', addListenerForOutsideMenuClick);
            document.addEventListener('touchmove', addListenerForOutsideMenuClick);
        }

        if(props.isSoftwareBlockedDueToLicenseExpiry){
            props.history.push("/licence")
        }

        selectItemOnRefresh();

        return () => {
            if (window.innerWidth <= 768) {
                document.removeEventListener('click', addListenerForOutsideMenuClick);
                document.removeEventListener('touchmove', addListenerForOutsideMenuClick);
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [menuItems?.length, props.isSoftwareBlockedDueToLicenseExpiry]);

    const currentLink = window.location.href.substring(window.location.href.lastIndexOf('#') + 1);
    let menuHiddenClass = showMenu ? '' : isMobileView ? 'mobile-side-menu' : 'hidden';

    const sideMenuDiv = (
        <div className={'position-relative d-print-none ' + menuHiddenClass} id="myMainMenu">

            <div className="side-menu-logo">
                {appContext && appContext === 'noHotel' ? (
                    <LogoWithoutText
                        onClick={() => {
                            props.history.push('/');
                        }}
                    ></LogoWithoutText>
                ) : (
                    <Logo
                        onClick={() => {
                            props.history.push('/');
                        }}
                    ></Logo>
                )}
                {/* <img style={{width:"160px"}} src={logo} alt="" /> */}
                {/* <h3>{props?.hotelName?.toUpperCase()}</h3> */}
                {!props.isMobileView ? (
                    <img
                        onClick={() => {
                            toggleMenu();
                        }}
                        src={showMenu ? chevronLeft : chevronRight}
                        alt=""
                    />
                ) : (
                    <img
                        onClick={() => {
                            props.showSideMenuProp(false);
                        }}
                        src={closeIcn}
                        alt=""
                    />
                )}
            </div>
            <div
                style={{ zIndex: 20, right: showMenu ? '-3px' : '-10px', top: '20px' }}
                className="position-absolute"
            ></div>
            <div id="animatethis">
                {Object.keys(menuItems).map((key, ind) => {
                    let isItemSelected = false;
                    if (menuItems[key].link === props.history.location.pathname) {
                        isItemSelected = true;
                    }
                    if (props.history.location?.search?.length > 0) {
                        if (menuItems[key].link !== props.history.location?.search?.length) {
                            isItemSelected = false;
                        }
                    }

                    const items = [];

                    if (menuItems[key]['items']) {
                        menuItems[key]['items'].forEach((subItem, ind2) => {
                            const isItemSelectedIfIncludesParams = subItem.link.includes(':')
                                ? checkIfLinkAndUrlMatchWithoutParams(subItem.link, currentLink)
                                : subItem.link === props.history.location.pathname;
                            let isSubitemSelected =
                                isItemSelectedIfIncludesParams ||
                                subItem.link.substring(1) === props.history.location.search;
                            if (isSubitemSelected && !showMenu && !isMobileView) {
                                isItemSelected = true;
                            }
                            items.push(
                                <PermissionAndFFlagCHeck item={subItem} key={ind2}>
                                    <div
                                        className={isSubitemSelected ? 'menu-item-selected' : ''}
                                        onClick={() => {
                                            selectSubItem(subItem);
                                        }}
                                    >
                                        <span className="item-label">{window.translate(subItem.itemLabel)}</span>
                                    </div>
                                </PermissionAndFFlagCHeck>
                            );
                        });
                    }

                    return (
                        <PermissionAndFFlagCHeck item={menuItems[key]} key={ind}>
                            <div className="side-menu-category">
                                <div
                                    key={menuItems[key]}
                                    onClick={() => {
                                        selectMainMenuItem(menuItems[key]);
                                    }}
                                    className="category-item"
                                >
                                    <div className={isItemSelected ? 'menu-item-selected' : ''}>
                                        {/* <img src={menuItems[key].icon}></img> */}
                                        <span>
                                            {menuItems[key].icon}{' '}
                                            <span className="item-label">
                                                {window.translate(menuItems[key].fullLabel)}
                                            </span>
                                        </span>
                                        <span>
                                            {menuItems[key]['items'] ? (
                                                <img
                                                    alt=""
                                                    src={
                                                        selectedMenuItems?.indexOf(menuItems[key]) > -1
                                                            ? chevronUp
                                                            : chevronDown
                                                    }
                                                ></img>
                                            ) : null}
                                        </span>
                                    </div>
                                </div>{' '}
                                <div className="side-menu-subitem-list ">
                                    {selectedMenuItems?.indexOf(menuItems[key]) > -1 ? items : <i></i>}{' '}
                                </div>
                            </div>
                        </PermissionAndFFlagCHeck>
                    );
                })}
            </div>
            <div className="side-menu-user-data-wrapper">
                <div className="horizontal-line"></div>
                <div className="side-menu-user-data-center-wrapper">
                    <div className="side-menu-user-data-center">
                        <div>
                            <span className="circle pointer">{loggedUser?.email.substring(0, 1).toUpperCase()}</span>
                            <div className="flex-column hide-on-menu-hidden">
                                <div style={{ fontWeight: 600 }}>{props?.hotelName?.toUpperCase()}</div>
                                <div>
                                    <span
                                        onClick={() => {
                                            props.history.push('/userProfile');
                                        }}
                                        className="user-email-data pointer"
                                    >
                                        {loggedUser?.name && loggedUser?.surname
                                            ? loggedUser?.name + ' ' + loggedUser?.surname
                                            : loggedUser?.email}
                                    </span>
                                </div>
                            </div>

                            {/* <i className='fa fa-home'></i> */}
                            {/* <img alt="" className='user-more-actions' src={chevronDown}></img> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
    if (isMobileView && showMenu) {
        return (
            <div className="d-flex h-100" style={{ marginRight: '5px', width: '15px', borderRight: '1px solid white' }}>
                {sideMenuDiv}
            </div>
        );
    } else {
        return sideMenuDiv;
    }
}

function generateFflagRecursive(arrayOfFlags, menuItemsToShow) {
    if (arrayOfFlags.length > 0) {
        let shiftedArrayOfFlags = arrayOfFlags.slice(1);
        return (
            <FeatureFlag flag={arrayOfFlags[0]} key={arrayOfFlags[0]}>
                {generateFflagRecursive(shiftedArrayOfFlags, menuItemsToShow)}
                {arrayOfFlags.length === 1 ? menuItemsToShow : null}
            </FeatureFlag>
        );
    }
}

function PermissionAndFFlagCHeck(props) {
    let featureFlaggedItem = null;
    if (props.item.featureFlags?.length > 0) {
        featureFlaggedItem = generateFflagRecursive(props.item.featureFlags, props.children);
    } else {
        featureFlaggedItem = props.children;
    }

    let permissionCheckedItem = null;
    if (props.item.permissionCheckName) {
        permissionCheckedItem = (
            <PermissionCheck name={props.item.permissionCheckName}>{featureFlaggedItem}</PermissionCheck>
        );
    } else {
        permissionCheckedItem = featureFlaggedItem;
    }
    return permissionCheckedItem;
}

export default SideMenu;
