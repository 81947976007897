import rest from '@data/restWrapper';

// src/pushServiceWorker.js
export const registerPushSW = async () => {
    if ('serviceWorker' in navigator) {
        try {
            const reg = await navigator.serviceWorker.register('/push-sw.js', { scope: '/' });
            // console.log('Push Service Worker registriran!', reg);
            // console.log('activate', activate);
            const subscription = await reg.pushManager.getSubscription();
            if (!subscription) {
                const newSubscription = await requestPushPermission();
                return newSubscription;
            } else {
                const checkIsValid = await rest('/getValidSubscription', 'POST', subscription);
                if (!checkIsValid) {
                    await subscription.unsubscribe();
                    const newSubscription = await requestPushPermission();
                    return newSubscription;
                }
            }
        } catch (error) {
            console.error('Push SW greška:', error);
        }
    }
};

const requestPushPermission = async () => {
    const permission = await Notification.requestPermission();
    if (permission === 'granted') {
        const publicKey = await rest('/getPublicVapidKey');
        // console.log('publicKey', publicKey);
        const reg = await navigator.serviceWorker.ready;
        // console.log('reg', reg);

        const subscription = await reg.pushManager.subscribe({
            userVisibleOnly: true,
            applicationServerKey: urlBase64ToUint8Array(publicKey.publicVapidKey),
        });

       const result =  await rest('/subscribeToPushNotifications', 'POST', subscription);
       console.log('result', result);

    }
};

function urlBase64ToUint8Array(base64String: string) {
    const padding = '='.repeat((4 - (base64String.length % 4)) % 4);
    const base64 = (base64String + padding).replace(/-/g, '+').replace(/_/g, '/');
    const rawData: any = window.atob(base64);
    return Uint8Array.from([...rawData].map((char) => char.charCodeAt(0)));
}
