import { savePersistentNotificationSystems } from './api';
import { PersistentNotificationSystemModel, UserInfo, ValueLabelString } from '@common/modelDefinition';

export const archiveNotification = async (notifications: PersistentNotificationSystemModel[]) => {
    const allNots: PersistentNotificationSystemModel[] = [];
    for (const notification of notifications) {
        const newNot = { ...notification };
        newNot.isActive = false;
        allNots.push(newNot);
    }

    await savePersistentNotificationSystems(allNots);
};

export const markAsReadNotification = async (
    notifications: PersistentNotificationSystemModel[],
    loggedUser: UserInfo
) => {
    const allNots: PersistentNotificationSystemModel[] = [];
    for (const notification of notifications) {
        const newNot = { ...notification };
        const readByUserUuids = notification.readByUserUuids
            ? typeof notification.readByUserUuids === 'string'
                ? JSON.parse(notification.readByUserUuids)
                : notification.readByUserUuids
            : [];
        if (readByUserUuids.includes(loggedUser.uuid)) {
            const newReadByUserUuids = readByUserUuids.filter((r: string) => r !== loggedUser.uuid);
            newNot.readByUserUuids = newReadByUserUuids;
        } else {
            newNot.readByUserUuids = [...readByUserUuids, loggedUser.uuid];
        }
        allNots.push(newNot);
    }
    await savePersistentNotificationSystems(allNots);
};

export const handleReplySubmit = async (replyInputs: any) => {
    if (replyInputs) {
        const newReply = { ...replyInputs };
        newReply.id = undefined;
        await savePersistentNotificationSystems([newReply]);
    }
};

export const getContextMenuOptions = (
    notifications: PersistentNotificationSystemModel[],
    loggedUser: UserInfo,
    activeTab: ValueLabelString,
    replyToAll?:boolean
) => {
    const contextMenuOptions: any[] = [];
    const enableArchive = notifications?.[0]?.archivingRoles
        ? typeof notifications[0].archivingRoles === 'string'
            ? JSON.parse(notifications[0].archivingRoles)
            : notifications[0].archivingRoles
        : [];
    if (activeTab.value === 'active') {
        replyToAll ?contextMenuOptions.push({ label: 'Reply to all' }) :  contextMenuOptions.push({ label: 'Reply' });
        if (enableArchive.includes(loggedUser?.role)) {
            contextMenuOptions.push({ label: 'Archive' });
        }
        contextMenuOptions.push({ label: 'Mark as seen' });
    } else if (activeTab.value === 'seen') {
        contextMenuOptions.push({ label: 'Mark as unseen' });
        if (enableArchive.includes(loggedUser?.role)) {
            contextMenuOptions.push({ label: 'Archive' });
        }
    }
    return contextMenuOptions;
};

export const getReplayNotificationTemplate = (
    notification: PersistentNotificationSystemModel,
    loggedUser: UserInfo,
    sendToAll?: boolean
) => {
    const newReply = { ...notification };
    // newReply.id = undefined;
    newReply.message = '';
    newReply.key = notification.key + '_reply' + new Date().getTime();
    newReply.timestamp = new Date().getTime();

    const parentReadByUserUuids = notification?.readByUserUuids
        ? typeof notification.readByUserUuids === 'string'
            ? JSON.parse(notification.readByUserUuids)
            : notification.readByUserUuids
        : [];

    const operatorName = `${loggedUser.name} ${loggedUser.surname}`;
    const operatorUuid = loggedUser.uuid;

    newReply.operatorName = operatorName;
    newReply.operatorUuid = operatorUuid;
    newReply.readByUserUuids = parentReadByUserUuids.includes(loggedUser.uuid) ? [loggedUser.uuid] : [];
    newReply.isActive = true;

    newReply.targetUserUuid = null;
    newReply.targetUserName = null
    if (!sendToAll) {
        if (notification.operatorUuid !== loggedUser.uuid) {
            //ja odgovaram na neciju poruku
            newReply.targetUserUuid = notification.operatorUuid;
            newReply.targetUserName = notification.operatorName;

        } else {
            // odgovaram na poruku koju sam kreirao
            if (notification.targetUserUuid) {
                newReply.targetUserUuid = notification.targetUserUuid
                newReply.targetUserName = notification.targetUserName;
            }
        }
    }

    return newReply;
};
