
import localeEn from './en';
import localeHr from './hr';
import localeSr from "./sr"
import useStore from '../../data/state/zustand';
import { overrides } from './overrides';

var translations = {
    hr: localeHr,
    en: localeEn,
    sr: localeSr,
};

// const dict = () => {
//     let language = useStore.getState().locale

//     if (translations[language]) {
//         return translations[language];
//     } else {
//         return translations.en;
//     }
// };
const dict = () => {
    const language = useStore.getState().locale;
    const appContext = useStore.getState().appContext;
    const baseTranslations = translations[language] || translations.en;
    const contextOverrides = appContext ? overrides[language]?.[appContext] || {} : {};
    return { ...baseTranslations, ...contextOverrides };
};

var getLanguagesInArray = () => {
    return Object.keys(translations);
};

// const translate = (text) => {
//     if (dict()[text]) {
//         return dict()[text];
//     } else {
//         return text;
//     }
// };

// const dictionaryTranslate = (text, dict) => {
//     if (dict) {
//         if(dict[text]){
//             return dict[text];
//         } else {
//             return text;
//         }
       
//     } else {
//         return translate(text);
//     }
// };

const translate = (text) => {
    const translations = dict();
    return translations[text] || text;
};

const dictionaryTranslate = (text, customDict) => {
    const translations = customDict || dict();
    return translations[text] || text;
};

var activeLanguage = ()=>{
    let language = useStore.getState().locale
    return language;
}

export default translate;

export { getLanguagesInArray, translations, translate, activeLanguage, dictionaryTranslate  };