import React from 'react';
import { ReactComponent as Home } from '../../assets/custom/icons/home.svg';
import { ReactComponent as PropertyManagement } from '../../assets/custom/icons/propertyManagement.svg';
import { ReactComponent as Household } from '../../assets/custom/icons/building.svg';
import { ReactComponent as Reports } from '../../assets/custom/icons/reports.svg';
import { ReactComponent as Pos } from '../../assets/custom/icons/pos.svg';
import { ReactComponent as RoomsAndHardware } from '../../assets/custom/icons/hardware.svg';
import { ReactComponent as Database } from '../../assets/custom/icons/database.svg';
import { ReactComponent as AdvancedSettings } from '../../assets/custom/icons/settings_cog.svg';
import { ReactComponent as Dollar } from '../../assets/custom/icons/price.svg';
import { ReactComponent as Finance } from '../../assets/custom/icons/bill.svg';
import { ReactComponent as Logout } from '../../assets/custom/icons/sign_out.svg';
import { ReactComponent as Help } from '../../assets/custom/icons/search.svg';
import { logout } from '../../data/actions';
import useStore from '@data/state/zustand';
import _ from "lodash"

function logMeOut() {
    logout();
}

function toggleMainViewButtons(mainViewType) {
    const uiStates = useStore.getState().uiStates.dashboard.showComponents
    let temp = uiStates ? [...uiStates] : []
    
    if (_.includes(temp, 'timeline')) {
        _.remove(temp, (t) => t === 'timeline');
    }
    if (_.includes(temp, 'tiles')) {
        _.remove(temp, (t) => t === 'tiles');
    }
    if (_.includes(temp, 'activity')) {
        _.remove(temp, (t) => t === 'activity');
    }
    if (_.includes(temp, 'occupancy')) {
        _.remove(temp, (t) => t === 'occupancy');

    }
// --   OVO OVDJE PROMIJENITI JOPA_-----
    if(mainViewType === "occupancy"){
        temp.push("sidebar_dailyInfo")
    }
    
    temp.push(mainViewType);
    useStore.getState().setNestedProperty(['uiStates', 'dashboard', 'showComponents'], temp)
}


const SideMenu = {
    
    home: {
        id: 'home',
        permissionCheckName: '',
        featureFlags: [],
        fullLabel: 'Home',
        label: 'Home',
        icon: <Home />,
        items: null,
        link: '/',
        whenClicked: () => {
            toggleMainViewButtons('timeline');
        },
        description: 'Hologic home page',
    },

    dashboard: {
        id: 'dashboard',
        permissionCheckName: 'dashboard',
        featureFlags: [],
        fullLabel: 'Front desk',
        label: 'Rooms',
        icon: <PropertyManagement />,
        items: [
            {
                itemLabel: 'Reservations',
                link: '/?reservations',
                permissionCheckName: 'dashboard_reservations',
                featureFlags: null,
                whenClicked: () => {
                    toggleMainViewButtons('timeline');
                },
            },
            {
                itemLabel: 'Group Reservations',
                link: '/groupReservations',
                permissionCheckName: 'dashboard_groupReservations',
                featureFlags: null,
                whenClicked: () => {
                    toggleMainViewButtons('timeline');
                },
            },
            {
                itemLabel: 'Journal',
                link: '/journal',
                permissionCheckName: 'dashboard_journal',
                featureFlags: null,
                whenClicked: () => {},
            },
            {
                itemLabel: 'Financial journal',
                link: '/financialJournal',
                permissionCheckName: 'dashboard_financialJournal',
                featureFlags: null,
                whenClicked: () => {},
            },
            {
                itemLabel: 'Analytics dashboard',
                link: '/analyticsDashboard',
                permissionCheckName: 'dashboard_analyticsDashboard',
                featureFlags: null,
                whenClicked: () => {},
            },
            {
                itemLabel: 'Room controllers',
                link: '/?rooms',
                permissionCheckName: 'dashboard_roomControllers',
                featureFlags: null,
                whenClicked: () => {
                    toggleMainViewButtons('tiles');
                },
            },
            {
                itemLabel: 'Card code management',
                link: '/cardCodeManagement',
                permissionCheckName: 'dashboard_cardCodeAdmin',
                featureFlags: null,
                whenClicked: () => {
                    toggleMainViewButtons('tiles');
                },
            },
            // { itemLabel: 'Household', link:  '/household', permissionCheckName:"household", featureFlags:null, whenClicked:()=>{toggleMainViewButtons("household")} },
            { itemLabel: 'Situation center', link:  '/allRoomEvents', permissionCheckName:"situationCenter", featureFlags:null, whenClicked:()=>{toggleMainViewButtons("situationCenter")} },
            { itemLabel: 'Board Consumption', link: '/posTerminal/boardConsumption/:posTerminalId', permissionCheckName:"dashboard_pos", featureFlags:null,  },
            { itemLabel: 'POS terminal', link: '/posTerminal/:posTerminalId', permissionCheckName:"dashboard_pos", featureFlags:null,  },
            { itemLabel: 'Closing revenue balance', link: '/posTerminal/stateAndInvoiceConclusion/:posTerminalId', permissionCheckName:"dashboard_inventory_revenueBalance", featureFlags:null,  },
            { itemLabel: 'Invoices', link: '/posTerminal/invoices/:posTerminalId', permissionCheckName:"dashboard_invoices", featureFlags:null,  },
            { itemLabel: 'Fiscalization Queue', link: '/posTerminal/fiscalizationQueue/:posTerminalId', permissionCheckName:"dashboard_invoices", featureFlags:null,  },

            { itemLabel: 'Group invoices', link: '/groupInvoices', permissionCheckName:"dashboard_groupInvoices", featureFlags:null, whenClicked:()=>{} },
            { itemLabel: 'Guests book', link: '/guestsBook', permissionCheckName:"dashboard_reservationConclusion", featureFlags:["development" ]},
         ],
        // link:"/",
        description: 'Everything for reception.',
    },

    household: {
        id: 'household',
        permissionCheckName: 'household',
        featureFlags: [],
        fullLabel: 'Household',
        label: 'Household',
        icon: <Household />,
        items: [
            { itemLabel: 'Dashboard', link: '/household', permissionCheckName:"household_dashboard", featureFlags:null, },
            { itemLabel: 'Maintainance', link: '/household/maintainance', permissionCheckName:"household_maintainance", featureFlags:null, },
            { itemLabel: 'Lost / Found', link: '/household/lostAndFound', permissionCheckName:"household_lostAndFound", featureFlags:null },
            // { itemLabel: 'Household refill setup', link: '/household/householdRefillSetup', permissionCheckName:"household_dashboard", featureFlags:null },
            { itemLabel: 'Task Templates', link: '/household/taskTemplates', permissionCheckName:"household_dashboard", featureFlags:null },
            { itemLabel: 'Household refill setup', link: '/household/refillSetup', permissionCheckName:"household_warehouse", featureFlags:null },
            { itemLabel: 'Household warehouses', link: '/household/warehouses', permissionCheckName:"household_warehouse", featureFlags:null },
            { itemLabel: 'Reports', link: '/household/reports', permissionCheckName:"household_warehouse", featureFlags:null },


         ],
        // link:"/",
        description: 'Household management.',
    },

    dataFolders: {
        id: 'dataFolders',
        permissionCheckName: 'foldersPanel',
        featureFlags: ['dataFolders'],
        fullLabel: 'Database',
        label: 'Data',
        icon: <Database />,
        items: [
            { itemLabel: 'Users', link: '/users', permissionCheckName: 'foldersPanel_users', featureFlags: null },
            {
                itemLabel: 'Customers',
                link: '/customers',
                permissionCheckName: 'foldersPanel_customers',
                featureFlags: null,
            },
            {
                itemLabel: 'Companies',
                link: '/companies',
                permissionCheckName: 'foldersPanel_companies',
                featureFlags: null,
            },
            {
                itemLabel: 'Discount Packages',
                link: '/loyaltyProgram',
                permissionCheckName: 'foldersPanel_loyaltyProgram',
                featureFlags: null,
            },
            {
                itemLabel: 'Reservations',
                link: '/reservations',
                permissionCheckName: 'foldersPanel_reservations',
                featureFlags: null,
            },
            {
                itemLabel: 'Rooms',
                link: '/roomsAdministration',
                permissionCheckName: 'foldersPanel_rooms',
                featureFlags: null,
            },
            {
                itemLabel: 'Taxes',
                link: '/priceLists/tax',
                permissionCheckName: 'foldersPanel_taxes',
                featureFlags: null,
            },
            {
                itemLabel: 'Accommodation',
                link: '/priceLists/accommodation',
                permissionCheckName: 'foldersPanel_accomodation',
                featureFlags: null,
            },
            {
                itemLabel: 'Currencies',
                link: '/priceLists/currencies',
                permissionCheckName: 'foldersPanel_currencies',
                featureFlags: null,
            },
            {
                itemLabel: 'POS goods',
                link: '/priceLists/posItem',
                permissionCheckName: 'posConfigurationPanel_posGoods',
                featureFlags: ['minibar'],
            },
            {
                itemLabel: 'POS Tables Layout',
                link: '/tablesLayout',
                permissionCheckName: 'posConfigurationPanel_tablesLayout',
                featureFlags: ['posDevelopment'],
            },
            {
                itemLabel: 'POS Terminals',
                link: '/posTerminal',
                permissionCheckName: 'posConfigurationPanel_posTerminal',
                featureFlags: ['posDevelopment'],
            },
            {
                itemLabel: 'Pricelist lookup',
                link: '/priceLists/priceListLookup',
                permissionCheckName: 'foldersPanel_pricelistLookup',
                featureFlags: null,
            },
        ],
        description: 'Create all models needed in application.',
    },

    goodsManagement: {
        id: 'goodsManagement',
        permissionCheckName: 'goodsManagementPanel',
        featureFlags: ['goodsManagement'],
        fullLabel: 'Retail management',
        label: 'Retail management',
        icon: <Pos />,
        items: [
            {
                itemLabel: 'SKU Items',
                link: '/goodsManagement/skuItems',
                permissionCheckName: 'goodsManagementPanel_stockKeepingUnits',
                featureFlags: ['goodsManagement'],
            },
            {
                itemLabel: 'POS goods',
                link: '/priceLists/posItem',
                permissionCheckName: 'goodsManagementPanel_posItems',
                featureFlags: ['minibar'],
            },
            {
                itemLabel: 'Stock card',
                link: '/goodsManagement/stockCard',
                permissionCheckName: 'goodsManagementPanel_stockCard',
                featureFlags: ['goodsManagement'],
            },
            {
                itemLabel: 'Supplier price lists',
                link: '/goodsManagement/supplierPriceLists',
                permissionCheckName: 'goodsManagementPanel_supplierPriceLists',
                featureFlags: ['goodsManagement'],
            },
            {
                itemLabel: 'Warehouses',
                link: '/goodsManagement/warehouses',
                permissionCheckName: 'goodsManagementPanel_warehouses',
                featureFlags: ['goodsManagement'],
            },
            {
                itemLabel: 'Documents',
                link: '/goodsManagement/documents',
                permissionCheckName: 'goodsManagementPanel_documents',
                featureFlags: ['goodsManagement'],
            },
            {
                itemLabel: 'Retail price calculation',
                link: '/goodsManagement/entryCalculation',
                permissionCheckName: 'goodsManagementPanel_entryCalculation',
                featureFlags: ['goodsManagement'],
            },
            {
                itemLabel: 'Price change logs',
                link: '/goodsManagement/priceChangeLogs',
                permissionCheckName: 'goodsManagementPanel_priceChangeLogs',
                featureFlags: ['goodsManagement'],
            },
            {
                itemLabel: 'Pricelist lookup',
                link: '/priceLists/priceListLookup',
                permissionCheckName: 'goodsManagementPanel_pricelistLookup',
                featureFlags: null,
            },
        ],
        description: 'All about goods management process.',
    },

    accountingAdmin: {
        id: 'accountingAdmin',
        permissionCheckName: 'accountingAdminPanel',
        featureFlags: ['finance'],
        fullLabel: 'Accounting Admin',
        label: 'Accounting Admin',
        icon: <Finance />,
        items: [
            {
                itemLabel: 'Chart of accounts',
                link: '/glAccounts',
                permissionCheckName: 'accountingAdminPanel_glAccounts',
                featureFlags: null,
            },
            {
                itemLabel: 'Accounting Report Definitions',
                link: '/glReportDocTypes',
                permissionCheckName: 'accountingAdminPanel_glReportDefinitions',
                featureFlags: null,
            },
            {
                itemLabel: 'Types and Classes Definitions',
                link: '/typesAndClasses',
                permissionCheckName: 'accountingAdminPanel_typesAndClasses',
                featureFlags: null,
            },
            {
                itemLabel: 'Accounting Automation Rules',
                link: '/glAutomationRules',
                permissionCheckName: 'accountingAdminPanel_automationRules',
                featureFlags: null,
            },
        ],
        description: 'Accounting Admin pages.',
    },
    accounting: {
        id: 'accounting',
        permissionCheckName: 'accountingPanel',
        featureFlags: ['finance'],
        fullLabel: 'Accounting',
        label: 'Accounting',
        icon: <Finance />,
        items: [
            {
                itemLabel: 'Accounting Journal',
                link: '/glDocuments',
                permissionCheckName: 'accountingPanel_accountingJournal',
                featureFlags: null,
            },
            {
                itemLabel: 'General Ledger Report',
                link: '/generalLedger',
                permissionCheckName: 'accountingPanel_generalLedger',
                featureFlags: null,
            },
            {
                itemLabel: 'Account cards',
                link: '/glAccountsCards',
                permissionCheckName: 'accountingPanel_glAccountsCards',
                featureFlags: null,
            },
            {
                itemLabel: 'Analytical cards',
                link: '/glAnalyiticalCards',
                permissionCheckName: 'accountingPanel_analyticalCards',
                featureFlags: null,
            },
            {
                itemLabel: 'Reports for period',
                link: '/currentGLReports',
                permissionCheckName: 'accountingPanel_currentGLReport',
                featureFlags: null,
            },
            {
                itemLabel: 'Finalized Accounting Reports',
                link: '/finalizedGLReports',
                permissionCheckName: 'accountingPanel__finalizedGLReport',
                featureFlags: null,
            },
        ],
        description: 'Accounting pages.',
    },

    roomsAndHardware: {
        id: 'roomsAndHardware',
        permissionCheckName: 'roomsPanel',
        featureFlags: [],
        fullLabel: 'Hardware',
        label: 'Rooms',
        icon: <RoomsAndHardware />,
        items: [
            { itemLabel: 'HW Router', link: '/srcProxies', permissionCheckName:"roomsPanel_HWProxy", featureFlags:null },
            { itemLabel: 'Printers', link: '/printerProxies', permissionCheckName:"roomsPanel_HWProxy", featureFlags:null },
            { itemLabel: 'Controllers', link: '/smartRoomControllers', permissionCheckName:"roomsPanel_controllers", featureFlags:null },
            { itemLabel: 'Alarm settings', link: '/alarmAndRoomModeInterpreter', permissionCheckName:"roomsPanel_AlarmAndRoomMode", featureFlags: null },
            { itemLabel: 'SRC templates', link: '/srcTemplates', permissionCheckName:"roomsPanel_SRCTemplates", featureFlags:["hardware"] },
            { itemLabel: 'Doors & rules', link: '/accessControl', permissionCheckName:"roomsPanel_doorsAndRules", featureFlags:null },
            { itemLabel: 'Whitelists', link: '/whiteLists', permissionCheckName:"roomsPanel_whitelists", featureFlags:null },
            { itemLabel: 'Document Scanners', link: '/docScannerDevices', permissionCheckName:"roomsPanel_documentScannerDevices", featureFlags:null },
        ],
        description:
            'Create or update hotel rooms informations. Declare SRC proxyes and bind their controllers with designated room. Create predefined SRC configurations and download them to selected rooms.',
    },

    // bms: {
    //     id:"bms",
    //     permissionCheckName: "gatewayIntegration",
    //     featureFlags: ["BMS"],
    //     fullLabel: 'BMS',
    //     label: 'Price lists',
    //     icon: <Bms/>,
    //     items:null,
    //     link:"/bms",
    //     description:"BMS"
    // },

    reports: {
        id: 'reports',
        permissionCheckName: 'reports',
        featureFlags: [],
        fullLabel: 'Reports',
        label: 'Reports',
        icon: <Reports />,
        items: [
            {
                itemLabel: 'Reservations',
                link: '/report/reservations',
                permissionCheckName: 'reservationsPanel',
                featureFlags: null,
            },
            {
                itemLabel: 'Invoices',
                link: '/report/invoices',
                permissionCheckName: 'invoicesPanel',
                featureFlags: null,
            },
            {
                itemLabel: 'Room events',
                link: '/report/roomEvents',
                permissionCheckName: 'roomEventsPanel',
                featureFlags: null,
            },
            {
                itemLabel: 'Access control report',
                link: '/report/accessControl',
                permissionCheckName: 'accessControlReportPanel',
                featureFlags: null,
            },
            {
                itemLabel: 'Retail management reports',
                link: '/report/retailManagement',
                permissionCheckName: 'goodsManagementReportPanel',
                featureFlags: ['goodsManagement'],
            },
        ],
        description: 'Reports list.',
    },

    advancedSettings: {
        id: 'advancedSettings',
        permissionCheckName: 'advancedSettingsPanel',
        featureFlags: [],
        fullLabel: 'Config',
        label: 'Config',
        icon: <AdvancedSettings />,
        items: [
            {
                itemLabel: 'Settings',
                link: '/generalSettings',
                permissionCheckName: 'advancedSettingsPanel_settings',
                featureFlags: null,
            },
            {
                itemLabel: 'Notification service settings',
                link: '/notificationTemplateSettings',
                permissionCheckName: 'advancedSettingsPanel_notificationServiceSettings',
                featureFlags: ['outgoingNotificationChannel'],
            },
            {
                itemLabel: 'Visa Info',
                link: '/visaInfo',
                permissionCheckName: 'advancedSettingsPanel_visaInfo',
                featureFlags: null,
            },
            {
                itemLabel: 'Self check in administration',
                link: '/selfCheckInAdministrative',
                permissionCheckName: 'advancedSettingsPanel_selfCheckInAdministration',
                featureFlags: null,
            },
            {
                itemLabel: 'Channel manager',
                link: '/channelManagerMapping/wuBook',
                permissionCheckName: 'advancedSettingsPanel_channelManager',
                featureFlags: ['channelManager'],
            },
            {
                itemLabel: 'POS Display Config',
                link: '/posDisplayConfig',
                permissionCheckName: 'advancedSettingsPanel_displayConfigInfo',
                featureFlags: ['posDevelopment'],
            },
            {
                itemLabel: 'Licence',
                link: '/licence',
                permissionCheckName: 'advancedSettingsPanel_licence',
                featureFlags: null,
            },

            // { itemLabel: 'About', link: '/about', permissionCheckName:"advancedSettingsPanel", featureFlags:null },
        ],
        description: 'Setup language, company informations, currency and modems configuration. Manage API keys.',
    },
    billing: {
        id: 'billing',
        permissionCheckName: '',
        featureFlags: ['billingModule'],
        fullLabel: 'Billing',
        label: 'Billing',
        icon: <Dollar />,
        items: [
            { itemLabel: 'Features', link: '/billing', permissionCheckName: '', featureFlags: null },
            { itemLabel: 'Billing invoices', link: '/billingInvoices', permissionCheckName: '', featureFlags: null },
        ],
        description: 'Setup language, company informations, currency and modems configuration. Manage API keys.',
    },
    help_documentation: {
        id: 'help_documentation',
        permissionCheckName: '',
        featureFlags: [],
        fullLabel: 'Help and documentation',
        label: 'Help and documentation',
        icon: <Help />,
        items: [
            { itemLabel: 'Help and support', link: '/help', permissionCheckName: '', featureFlags: null },
            { itemLabel: 'Documentation', link: '/documentation', permissionCheckName: '', featureFlags: null },
        ],
        description: 'Find help or contact us.',
    },

    logout: {
        id: 'logout',
        permissionCheckName: '',
        featureFlags: [],
        fullLabel: 'Log out',
        label: 'Log out',
        icon: <Logout />,
        whenClicked: () => {
            logMeOut();
        },
        // items: [],
        description: 'All business conclusions in one place.',
    },
};

export default SideMenu;
