import React, { useState } from 'react';
import translate from '@data/translations';
import CompanyForm from '../company/form';
import CompanySearchForm from '../company/searchForm';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import { CompanyModel, LoyaltyProgramModel } from '@common/modelDefinition';
import countryCodes from '../../data/countryCodes.json';
import citizenshipCodes from '../../data/citizenshipCode.json';
import { WorldLanguages } from '@data/worldLanguages';
//@ts-ignore
import { formatDate, parseDate } from 'react-day-picker/moment';
import YearMonthForm from '@components/yearDayCalendarSelect';
import useStore from '@data/state/zustand';

const FormInputs = (props: any) => {
    const {
        customer,
        handleInputChange,
        // handleLanguageChange,
        company,
        setCustomer,
        setCompany,
        checkValidationInputs,
        refInputs,
    } = props;

    const [_fromMonth, set_fromMonth] = useState(new Date());
    const loyaltyPrograms: LoyaltyProgramModel[] = useStore((appState) => appState.model.LoyaltyProgram);
    const otherSettings = useStore.getState().otherSettings;

    React.useEffect(() => {
        checkValidationInputs(customer);
        // eslint-disable-next-line
    }, []);

    const deleteCompanyReference = () => {
        const _customer = { ...customer };
        _customer.companyId = null;
        setCustomer({ ..._customer });
        setCompany(null);
    };

    const genders = [];
    genders.push({ key: 'M', label: translate('Male') });
    genders.push({ key: 'F', label: translate('Female') });
    if (otherSettings?.csvDownloadTemplate !== 'eVisitor') {
        genders.push({ key: 'Other', label: translate('Undefined') });
    }

    let countryOptions = countryCodes.map((c: any) => {
        return (
            <option value={c['country-code']} key={c['country-code']}>
                {translate(c.name)}
            </option>
        );
    });
    countryOptions.unshift(
        <option key="none" value="">
            {' '}
            -{' '}
        </option>
    );
    let nationalityOptions = citizenshipCodes.map((n: any) => {
        return (
            <option value={n['num_code']} key={n['num_code']}>
                {translate(n.nationality)}
            </option>
        );
    });
    nationalityOptions.unshift(
        <option key="none" value="">
            {' '}
            -{' '}
        </option>
    );

    const languageList = Object.keys(WorldLanguages);

    // const optionList = languageList.map((itm) => {
    //     return { label: WorldLanguages[itm].name, value: itm };
    // });

    let langOptions = languageList.map((itm: any) => {
        return (
            <option value={itm} key={itm}>
                {translate(WorldLanguages[itm].name)}
            </option>
        );
    });
    langOptions.unshift(
        <option key="none" value="">
            {' '}
            -{' '}
        </option>
    );

    let selectedCompanyName = company ? company.name : null;

    const handleYearMonthChange = (e: any) => {
        set_fromMonth(e);
    };

    const loyaltyProgramOptions = loyaltyPrograms
        .map((program: LoyaltyProgramModel) => {
            if (program.isActive) {
                return (
                    <option value={program.id} key={program.name}>
                        {translate(program.name)}
                    </option>
                );
            } else {
                return null;
            }
        })
        ?.filter((ly) => ly !== null);

    loyaltyProgramOptions.unshift(
        <option key="none" value="">
            {' '}
            -{' '}
        </option>
    );

    return (
        <div>
            <div className=" display-flex mt-12p">
                <div className="mr-8p flex">
                    <label className="text-bold ml-4p " htmlFor="">
                        {translate('First name')}: <span className="color-secondary">({translate('required')})</span>
                    </label>
                    <input
                        ref={refInputs.firstNameRef}
                        autoComplete="off"
                        placeholder={translate('John')}
                        name="firstName"
                        value={customer.firstName ? customer.firstName : ''}
                        onChange={handleInputChange}
                        type="text"
                        className="input input-modal"
                    />
                </div>
                <div className="ml-8p flex">
                    <label className="text-bold ml-4p" htmlFor="">
                        {translate('Last name')}: <span className="color-secondary">({translate('required')})</span>
                    </label>
                    <input
                        ref={refInputs.lastNameRef}
                        autoComplete="off"
                        name="lastName"
                        placeholder={translate('Doe')}
                        value={customer.lastName ? customer.lastName : ''}
                        onChange={handleInputChange}
                        type="text"
                        className="input input-modal"
                    />
                </div>
            </div>

            <div className="mt-12p">
                <label className="text-bold ml-4p" htmlFor="">
                    {translate('Company')}:
                </label>
                {selectedCompanyName === null ? (
                    <CompanySearchForm
                        createButtonText={translate('New Company')}
                        createForm={CompanyForm}
                        showCreateButton={true}
                        callback={(selectedCompany: CompanyModel) => {
                            setCompany(selectedCompany);
                        }}
                    />
                ) : (
                    <div className="flex-center">
                        <input
                            autoComplete="off"
                            disabled
                            name="companyId"
                            value={selectedCompanyName}
                            type="text"
                            className="input input-modal"
                        />
                        <div className="input-group-append">
                            <button
                                onClick={deleteCompanyReference}
                                className="btn btn-outline-danger btn-sm"
                                type="button"
                            >
                                <i className="fa fa-trash" aria-hidden="true"></i>
                            </button>
                        </div>
                    </div>
                )}
            </div>

            <div className="display-flex mt-12p">
                <div className="flex">
                    <label className="text-bold ml-4p" htmlFor="">
                        {translate('Birth Date')}
                    </label>
                    <div>
                        <DayPickerInput
                            inputProps={{
                                ref: refInputs.birthDateRef,
                                className:
                                    'input input-modal ' + (customer?.dateOfBirth ? '  ' : ' is-invalid-warning'),
                            }}
                            dayPickerProps={{
                                todayButton: translate('Today'),
                                className: 'text-center',
                                // fromMonth:_fromMonth,
                                month: _fromMonth,
                                firstDayOfWeek: 1,
                                //@ts-ignore
                                captionElement: ({ date }) => (
                                    <YearMonthForm
                                        date={date}
                                        onChange={handleYearMonthChange}
                                        minYear={1900}
                                        // maxYear={1900}
                                    />
                                ),
                            }}
                            // name="dateOfBirth"
                            value={customer.dateOfBirth ? new Date(customer.dateOfBirth) : ''}
                            formatDate={formatDate}
                            parseDate={parseDate}
                            placeholder={otherSettings?.dateFormat ? otherSettings.dateFormat : 'DD/MM/YYYY'}
                            format={otherSettings?.dateFormat ? otherSettings.dateFormat : 'DD/MM/YYYY'}
                            // classNames={{ container: 'input-modal', overlayWrapper: '', overlay: '' }}
                            onDayChange={(d) => {
                                customer['dateOfBirth'] = new Date(d).setHours(12, 0, 0);
                                setCustomer({ ...customer });
                            }}
                        />
                    </div>
                </div>
                <div className="ml-2p mr-8p flex">
                    <label className="text-bold ml-4p" htmlFor="">
                        {translate('Birth Place')}:
                    </label>
                    <input
                        autoComplete="off"
                        name="birthTown"
                        placeholder={translate('Mostar')}
                        value={customer.birthTown ? customer.birthTown : ''}
                        onChange={handleInputChange}
                        type="text"
                        className="input input-modal"
                    />
                </div>
                <div className="ml-2p ml-8p flex">
                    <label className="text-bold ml-4p" htmlFor="">
                        {translate('Birth country')}:
                    </label>
                    <select
                        ref={refInputs.birthCountryRef}
                        name="birthCountry"
                        className="input input-modal"
                        value={customer.birthCountry ? customer.birthCountry : ''}
                        onChange={handleInputChange}
                    >
                        {countryOptions}
                    </select>
                </div>
            </div>

            <div className="display-flex mt-12p">
                <div className="flex">
                    <label className="text-bold ml-4p" htmlFor="">
                        {translate('Gender')}
                    </label>
                    <div className="display-flex ml-4p" ref={refInputs.genderInput}>
                        {genders.map((g) => {
                            return (
                                <div className="form-check text-left mr-20p flex" key={g.key}>
                                    <input
                                        // ref={refInputs.genderRef}
                                        className="form-check-input"
                                        type="radio"
                                        name={g.key}
                                        id={g.key}
                                        value={g.key}
                                        checked={customer.gender && customer.gender === g.key ? true : false}
                                        onChange={(e) => {
                                            customer['gender'] = e.target.value;
                                            setCustomer({ ...customer });
                                        }}
                                    />
                                    <label className="form-check-label" htmlFor={g.key}>
                                        {g.label}
                                    </label>
                                </div>
                            );
                        })}
                    </div>
                </div>
                <div className="mr-8p ml-2p flex">
                    <label className="text-bold ml-4p" htmlFor="">
                        {translate('Email')}:
                    </label>
                    <input
                        ref={refInputs.emailRef}
                        autoComplete="off"
                        name="email"
                        placeholder={translate('mail@mail.com')}
                        value={customer.email ? customer.email : ''}
                        onChange={handleInputChange}
                        type="email"
                        className="input input-modal"
                    />
                </div>
                <div className="ml-8p flex">
                    <label className="text-bold ml-4p" htmlFor="">
                        {translate('Mobile number')}:
                    </label>
                    <input
                        autoComplete="off"
                        name="phoneOrMobile"
                        placeholder={translate('0038763000000')}
                        value={customer.phoneOrMobile ? customer.phoneOrMobile : ''}
                        onChange={handleInputChange}
                        type="text"
                        className="input input-modal"
                    />
                </div>
            </div>
            <div className="display-flex mt-12p">
                <div className="mr-8p flex">
                    <label className="text-bold ml-4p" htmlFor="">
                        {translate('Address')}:
                    </label>
                    <input
                        autoComplete="off"
                        name="address"
                        placeholder={translate('Ulica Nike Nikic 4')}
                        value={customer.address ? customer.address : ''}
                        onChange={handleInputChange}
                        type="text"
                        className="input input-modal"
                    />
                </div>
                <div className="ml-8p mr-8p flex">
                    <label className="text-bold ml-4p" htmlFor="">
                        {translate('Post office number')}:
                    </label>
                    <input
                        autoComplete="off"
                        name="poNumber"
                        placeholder={translate('54545')}
                        value={customer.poNumber ? customer.poNumber : ''}
                        onChange={handleInputChange}
                        type="text"
                        className="input input-modal"
                    />
                </div>
                <div className="ml-8p flex">
                    <label className="text-bold ml-4p" htmlFor="">
                        {translate('Town')}:
                    </label>
                    <input
                        autoComplete="off"
                        name="town"
                        placeholder={translate('Mostar')}
                        value={customer.town ? customer.town : ''}
                        onChange={handleInputChange}
                        type="text"
                        className="input input-modal"
                    />
                </div>
            </div>

            <div className="display-flex mt-12p">
                <div className="mr-8p flex">
                    <label className="text-bold ml-4p" htmlFor="">
                        {translate('Country/State')}:
                    </label>
                    <select
                        ref={refInputs.countryRef}
                        name="country"
                        className="input input-modal"
                        value={customer.country ? customer.country : ''}
                        onChange={handleInputChange}
                    >
                        {countryOptions}
                    </select>
                </div>

                <div className="ml-8p flex">
                    <label className="text-bold ml-4p" htmlFor="">
                        {translate('Citizenship')}:
                    </label>
                    <select
                        ref={refInputs.citizenshipRef}
                        name="nationality"
                        className="input input-modal"
                        value={customer.nationality ? customer.nationality : ''}
                        onChange={handleInputChange}
                    >
                        {nationalityOptions}
                    </select>
                </div>
            </div>

            <div className="display-flex mt-12p">
                <div className="mr-8p flex">
                    <label className="text-bold ml-4p" htmlFor="">
                        {translate('Preferred language')}:
                    </label>
                    <select
                        name="preferredLanguage"
                        className="input input-modal"
                        value={customer.preferredLanguage ? customer.preferredLanguage : ''}
                        onChange={handleInputChange}
                    >
                        {langOptions}
                    </select>
                </div>

                <div className="ml-8p flex">
                    <label className="text-bold ml-4p" htmlFor="">
                        {translate('Other language')}:
                    </label>
                    <select
                        name="otherLanguage"
                        className="input input-modal"
                        value={customer.otherLanguage ? customer.otherLanguage : ''}
                        onChange={handleInputChange}
                    >
                        {langOptions}
                    </select>
                </div>

                <div className="ml-8p flex">
                    <label className="text-bold ml-4p" htmlFor="">
                        {translate('Discount Packages')}:
                    </label>
                    <select
                        name="loyaltyProgramId"
                        className="input input-modal"
                        value={customer.loyaltyProgramId ? customer.loyaltyProgramId : ''}
                        onChange={handleInputChange}
                    >
                        {loyaltyProgramOptions}
                    </select>
                </div>
            </div>

            <div className="mt-12p display-flex">
                <div className="flex mr-8p">
                    <label className="text-bold ml-4p" htmlFor="">
                        {translate('Notes')}:
                    </label>
                    <textarea
                        name="notes"
                        placeholder={translate('Notes')}
                        onChange={(e) => {
                            customer.params[e.target.name] = e.target.value;
                            setCustomer({ ...customer }); //spread to force refresh
                        }}
                        value={customer?.params?.notes ? customer.params.notes : ''}
                        className="input input-modal"
                        rows={2}
                    ></textarea>
                </div>
            </div>

            <div className="mt-12p">
                <i
                    onClick={() => {
                        customer.params['newsletter'] = !customer.params['newsletter'];
                        setCustomer({ ...customer }); //spread to force refresh
                    }}
                    className={
                        customer?.params?.newsletter ? 'fa fa-check-square-o fa-lg mr-8p' : 'fa fa-square-o fa-lg mr-8p'
                    }
                ></i>
                <label className="text-bold ml-4p" htmlFor="newsletter_id">
                    {translate('Newsletter subscriptions')}
                </label>
            </div>
        </div>
    );
};

export default FormInputs;
