import moment from 'moment';
import {
    TaskManagementHeaderEditModel,
    TaskManagementItemEditModel,
    TaskManagementItemModel,
    UserInfo,
    StockConsumption,
    HouseholdRefillSetupModel,
    WarehouseItemModel,
    StockLogModel,
} from '@common/modelDefinition';
import rest from '@data/restWrapper';
import { Assigments, GetRequiresCleaningRooms } from './interfaces';
// import { startGlobalLoading, stopGlobalLoading } from '@components/loaders/globalLoading';

export const getRoomReservationStatusesForPeriod = async (selectedDate: number) => {
    const roomReservationStatuses = await rest('/getRoomReservationStatusesForPeriod', 'POST', { selectedDate });
    return roomReservationStatuses;
};

export const getTaskTemplates = async () => {
    const taskTemplates = await rest('/getTaskTemplates');
    return taskTemplates;
};
export const createOrUpdateTaskItem = async (taskItem: TaskManagementItemModel) => {
    const _taskItem = await rest('/createOrUpdateTaskItem', 'POST', taskItem);
    return _taskItem;
};

export const deleteTaskItem = async (taskId: number) => {
    await rest('/deleteTaskItem', 'POST', { taskId });
};

export async function getMaids(): Promise<UserInfo[]> {
    return await rest('/getEmployeesByRole/maid');
}

export async function getTaskHeader(taskHeaderId: number): Promise<TaskManagementHeaderEditModel> {
    return await rest('/getTaskHeader/' + taskHeaderId);
}

export async function getTaskHeaderForDate(selectedDate: number): Promise<TaskManagementHeaderEditModel | null> {
    const _selectedDate = new Date(selectedDate).setHours(11, 59, 59);
    return await rest('/getTaskHeaderForDate', 'POST', { selectedDate: _selectedDate });
}
export async function createTaskHeader(selectedDate: number): Promise<TaskManagementHeaderEditModel> {
    const taskHeaderObj = {
        name: 'AutoGenerate',
        validFrom: moment(selectedDate).startOf('day').valueOf(),
        validTo: moment(selectedDate).endOf('day').valueOf(),
        taskHeaderGroup: 'dailyTaskHeader',
        roomInfoId: null,
        reservationId: null,
    };
    return await rest('/createTaskHeader', 'POST', taskHeaderObj);
}

export async function getTaskItemsByTaskHeaderId(taskHeaderId: number): Promise<TaskManagementItemEditModel[]> {
    return await rest('/getTaskItemsByTaskHeaderId/' + taskHeaderId);
}

export async function assignNewTaskItems(
    assigments: Assigments,
    taskType: string,
    includeEmptyRooms: boolean,
    viewType: string,
    requiresQuery: boolean,
    targetTaskTemplatesIds?: number[]
): Promise<TaskManagementItemEditModel[]> {
    const res = await rest('/assignNewTaskItems', 'POST', {
        assigments,
        taskType,
        includeEmptyRooms,
        viewType,
        requiresQuery,
        targetTaskTemplatesIds,
    });
    return res;
}

export async function createOrUpdateTaskItems(taskItems: TaskManagementItemModel[], type?: string) {
    if (taskItems.length === 0) {
        return [];
    }
    return await rest('/createOrUpdateTaskItems', 'POST', { taskItems, type });
}



export async function createHouseholdStockLog(
    stockConsumption: StockConsumption[],
    recordOnReservation: boolean,
    permissionRequired: boolean,
    confirmed: boolean,
    autoSuggestWriteOff:boolean,
): Promise<void> {
    // ?? ubaciti taskManagementheaderid

    await rest('/createHouseholdStockLog', 'POST', {
        stockConsumption: stockConsumption,
        recordOnReservation: recordOnReservation ? true : false,
        permissionRequired: permissionRequired,
        confirmed,
        autoSuggestWriteOff,
    });
}
export async function getPosTerminalItemsByWarehouseId(warehouseId: number): Promise<any[]> {
    return await rest('/getPosTerminalItemsByWarehouseId/' + warehouseId);
}
export async function saveReservationConsumption(stockConsumption: StockConsumption[]) {
    await rest('/saveReservationConsumption', 'POST', stockConsumption);
}

export async function getWarehouseStockLogs(warehouseId: number, from?: number, to?: number): Promise<any[]> {
    return await rest('/getWarehouseStockLogs/', 'POST', { warehouseId, from, to });
}

//JANITOR

export interface GetMaintananceTaskItems {
    roomInfoId?: number;
    from?: number;
    to?: number;
}
export async function getMaintananceTaskItems(props: GetMaintananceTaskItems): Promise<TaskManagementItemEditModel[]> {
    const { from, to, roomInfoId } = props;
    return await rest('/getMaintananceTaskItems/' + roomInfoId, 'POST', { roomInfoId, from, to });
}
//LOST AND FOUND

export async function getLostAndFoundTaskItems(roomInfoId?: number): Promise<TaskManagementItemEditModel[]> {
    const lostAndFoundTaskItems = await rest('/getLostAndFoundTaskItems/' + roomInfoId);
    return lostAndFoundTaskItems
}

export async function getRequiresCleaningRooms(
    assigments: Assigments,
    taskType: string,
    includeEmptyRooms: boolean,
    viewType: string,
    targetTaskTemplatesIds?: number[]
): Promise<GetRequiresCleaningRooms> {
    // startGlobalLoading();

    const tasks = await rest('/assignNewTaskItems', 'POST', {
        assigments,
        taskType,
        includeEmptyRooms,
        viewType,
        requiresQuery: true,
        targetTaskTemplatesIds,
    });

    const filteredTasks: TaskManagementItemModel[] = [];

    const requiresCleaningRooms: number[] = [];
    for (const task of tasks) {
        if (task.roomInfoId) {
            if (!requiresCleaningRooms.includes(task.roomInfoId)) {
                requiresCleaningRooms.push(task.roomInfoId);
            }
            const newTask = { ...task };
            newTask.userAssigned = null;
            filteredTasks.push(newTask);
        }
    }
    // stopGlobalLoading();
    return { requiresCleaningRooms: requiresCleaningRooms, taskItems: filteredTasks };
}

export const getHouseholdStaff = async () => {
    const householdStaff = await rest('/getHouseholdStaff');
    return householdStaff;
};
export const getLastCleanedTimestamps = async (roomInfoIds: number[]) => {
    const lastCleanedTimestamps = await rest('/getLastCleanedTimestamps', 'POST', { roomInfoIds });
    return lastCleanedTimestamps;
};

export const getHouseholdRefillSetups = async () => {
    const householdRefillSetups = await rest('/getHouseholdRefillSetups', 'GET');
    return householdRefillSetups;
};

export const saveHouseholdRefillSetups = async (householdRefillSetups: HouseholdRefillSetupModel[]) => {
    const payloadRefillSetups = householdRefillSetups?.filter((h) => h.posItemId);
    await rest('/saveHouseholdRefillSetups', 'POST', { householdRefillSetups: payloadRefillSetups });
};

export const getItemsFromSearchBox = async (path: string) => {
    const itemsFromSearchBoxe = await rest(path);
    return itemsFromSearchBoxe;
};

export const getWarehouseItems = async () => {
    const warehouseItems = await rest('/getWarehouseItems', 'GET');
    return warehouseItems;
};

export const saveWarehouseItems = async (warehouseItems: WarehouseItemModel[]) => {
    await rest('/saveWarehouseItems', 'POST', { warehouseItems: warehouseItems });
};

export async function getPosItems() {
    const { data: _posItems } = await rest('/getPosItemsWithSkuPluRates', 'GET');
    return _posItems;
}

export const deleteWarehousItemHandler = async (warehouseItem: WarehouseItemModel) => {
    return await rest('/WarehouseItem/' + warehouseItem.id, 'DELETE');
};
export const closeHouseholdWriteOffLogs = async (stockLogs: StockLogModel[]) => {
    return await rest('/closeHouseholdWriteOffLogs', 'POST', { stockLogs });
};

export const getStockDocItemsByDate = async (selectedDate: number) => {
    const stockDocItems = await rest('/getStockDocItemsByDate', 'POST', { selectedDate });
    return stockDocItems
};