import {
    //  InvoiceModel,
    // UserInfo,
    InvoiceModelWithInvoiceItems,
    InvoiceConclusionModel,
    PaginatedData,
    ReservationGuestBoard,
    InvoiceModel,
} from '@common/modelDefinition';

import rest from '@data/restWrapper';

export async function getUsers(): Promise<PaginatedData> {
    return await rest('/getPagedUsers?page=' + 0 + '&rows=' + 500, 'GET');
}

export async function getLatestPosTerminalInvoices(posTerminalId: number) {
    return await rest('/getLatestPosTerminalInvoices/' + posTerminalId);
}

export async function searchInvoice(searchString: string): Promise<InvoiceModelWithInvoiceItems[]> {
    const res = await rest(`/searchInvoice?search=${searchString}`);
    return res;
}

export async function getInvoiceReconciliation(posTerminalId: number, reportType?: string) {
    const res = await rest('/getInvoiceReconciliation', 'POST', { posTerminalId, reportType });
    return res;
}
export async function createInvoiceConclusion(invoiceConclusion: InvoiceConclusionModel, reportType?: string) {
    const res = await rest('/createInvoiceConclusion', 'POST', { invoiceConclusion, reportType, group: 'type' });

    return res;
}
export async function getInvoiceConclusions(posTerminalId: number, from: number, to: number) {
    const res = await rest('/getInvoiceConclusions/' + posTerminalId + '?from=' + from + '&to=' + to);

    return res;
}

export async function fiscalizeConcludedInvoice(invoiceId: number): Promise<InvoiceModel | void> {
    try {
        const invoice = await rest('/fiscalizeConcludedInvoice', 'POST', { invoiceId, checkFirst:true });
        return invoice; 
    } catch (error) {
        console.log('fiscalizeConcludedInvoice Error',error)
    }
  
}
export async function editConcludedInvoice(invoice: InvoiceModel): Promise<InvoiceModel> {
    const _invoice = await rest('/Invoice/' + invoice.id, 'PUT', invoice);
    return _invoice;
}
export async function getGeneralSettingsByPrefix() {
    const genericNotes = await rest('/getGeneralSettingsByPrefix/note_');
    return genericNotes;
}


interface GetInvoiceConclusionDetailsProps {
    invoiceConclusionId?: number;
    posTerminalId: number;
    group: string;
    subtype?: string;
}

interface GetBartendersReportProps {
    invoiceConclusionId?: number;
    posTerminalId: number;
    bartenderUuid?: string;
}

export async function getInvoiceConclusionDetails(props: GetInvoiceConclusionDetailsProps) {
    const { invoiceConclusionId, posTerminalId, group, subtype } = props;
    const res = await rest('/getInvoiceConclusionDetails', 'POST', {
        invoiceConclusionId,
        posTerminalId,
        group,
        subtype,
    });

    return res;
}

export async function getFinancialJournalDetails(props: any) {
    const { group, from, to } = props;
    const res = await rest('/getFinancialJournalDetails', 'POST', { group, from, to });
    return res;
}

export async function getBartendersReport(props: GetBartendersReportProps) {
    const { invoiceConclusionId, posTerminalId, bartenderUuid } = props;
    const res = await rest('/pos/getBartendersReport', 'POST', { invoiceConclusionId, posTerminalId, bartenderUuid });

    return res;
}

export async function updateInvoiceConslusion(invoiceConslusion: InvoiceConclusionModel) {
    await rest('/InvoiceConclusion/' + invoiceConslusion.id, 'PUT', invoiceConslusion);
}

export const getReservationGuestBoardsPreview = async (from: number, to: number) => {
    const reservationGuestBoards: ReservationGuestBoard[] = await rest('getReservationGuestBoardsPreview', 'POST', {
        from,
        to,
    });
    return reservationGuestBoards;
};

export const getReservationGuestBoards = async (timestamp:number) => {
    const reservationGuestBoards: ReservationGuestBoard[] = await rest('getReservationGuestBoards', 'POST', {
        timestamp
    });
    return reservationGuestBoards;
};

export const saveReservationGuestBoards = async (reservationGuestBoards:ReservationGuestBoard[]) => {
    const _reservationGuestBoards: ReservationGuestBoard[] = await rest('saveReservationGuestBoards', 'POST', {
        reservationGuestBoards,
        
    });
    return _reservationGuestBoards;
};

export const deleteReservationGuestBoards = async (reservationGuestBoards:ReservationGuestBoard[]) => {
    const _reservationGuestBoards: ReservationGuestBoard[] = await rest('deleteReservationGuestBoards', 'POST', {
        reservationGuestBoards,
        
    });
    return _reservationGuestBoards;
};

export const printBoardConsumptions = async (reservationGuestBoards:ReservationGuestBoard[]) => {
    const _reservationGuestBoards: ReservationGuestBoard[] = await rest('printBoardConsumptions', 'POST', {
        reservationGuestBoards,
        
    });
    return _reservationGuestBoards;
};


export async function getLatestPosTerminalInvoiceQueues(posTerminalId: number) {
    return await rest('/getLatestPosTerminalInvoiceQueues/' + posTerminalId);
}

export async function getInvoiceById(invoiceId: number) {
    return await rest('/getInvoiceById/' + invoiceId);
}

export async function forceUpdateInvoiceQueueAsSuccess(id: number) {
    try {
        return await rest('/forceUpdateInvoiceQueueAsSuccess/' +id,'POST' ,{
            status:5,        
        }); 
    } catch (error) {
            console.log('forceUpdateInvoiceQueueAsSuccess ERROR',error)
    }

}
