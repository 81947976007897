import React from 'react';
//@ts-ignore
import _ from 'lodash';
import rest from '../../data/restWrapper';
//@ts-ignore
import { toast } from 'react-toastify';
import { closeModal } from '@components/modal';
import { CompanyModel, CustomerModel } from '@common/modelDefinition';
import translate from '@data/translations';
import { DocumentScanner, CustomersListAfterScann } from './DocumentScanner';
import FormInputs from './formInputs';
import { documentTypesEVisitor } from '@common/eGuestsData/eVisitor';
import useStore from '@data/state/zustand';
import { Reservation } from '@pages/newReservations/resController';
import ConfirmButtons from '@components/buttons/confirmButtons';

interface CustomerFormProps {
    showPageTitle?: boolean;
    customHeader?: any;
    cancelHandler?: any;
    history?: any;
    swapCustomer?: any;
    customer?: CustomerModel;
    match?: any;
    customerId?: number | null;
    callback?: any;
    reservation?: Reservation | null;
}

const CustomerForm = (props: CustomerFormProps) => {
    const {
        customHeader = null,
        cancelHandler,
        history,
        reservation,
        //sanja dodala customer da može preko propsa. trebalo biti biti drugo ime, ali pravilo je problem u useEffect-u i sada se direktno seta u state ako je prosljeđeno tako.
        //callback = undefined, //optional callback handler
    } = props;

    const docScan = useStore((appState: any) => appState.model.DocumentScan);
    const otherSettings = useStore((appState: any) => appState.otherSettings);
    var { swapCustomer = undefined } = props; //optional swap override for reservations swap

    const [customer, setCustomer] = React.useState(props.customer ? props.customer : ({ params: {} } as CustomerModel));
    const [company, setCompany] = React.useState(null as CompanyModel | null);
    const [loading, setLoading] = React.useState(false);
    const [conflictingCustomerId, setConflictingCustomerId] = React.useState(null); //by same document id
    const [showDocumentScannerData, setShowDocumentScannerData] = React.useState(false);
    const [customersAfterScan, setCustomersAfterScan] = React.useState({
        data: [],
        typeOfScan: 0,
        visaInfo: null,
        customer: {} as CustomerModel,
    });

    const customerId = props.customerId ? props.customerId : props.match?.params.customerId;

    //validation refs
    const firstNameRef = React.useRef(null);
    const lastNameRef = React.useRef(null);
    const emailRef = React.useRef(null);
    const documentIdRef = React.useRef(null);
    const documentTypeRef = React.useRef(null);
    const birthDateRef = React.useRef(null);
    const birthCountryRef = React.useRef(null);
    const citizenshipRef = React.useRef(null);
    const countryRef = React.useRef(null);
    const genderRef = React.useRef(null);

    var checkIfDocumentIdIsUniqueDebouncer: any = null; //debouncer holder

    React.useEffect(() => {
        const loadFormData = async (cid: number) => {
            setLoading(true);
            let cmp = null;
            const c = await rest('/Customer/' + cid);
            if (c.params) {
                c.params = JSON.parse(c.params);
            } else {
                c.params = {};
            }
            if (c.companyId) {
                cmp = await rest('/Company/' + c.companyId);
            }
            setCustomer(c);
            setCompany(cmp);
            setConflictingCustomerId(null);
            setLoading(false);
            checkValidationInputs(c);
        };
        if (customerId) {
            loadFormData(customerId);
        }
        // eslint-disable-next-line
    }, [customerId]);

    React.useEffect(() => {
        const loadDocScann = async () => {};

        loadDocScann().catch((err) => {
            console.log(err);
        });
        // eslint-disable-next-line
    }, [docScan?.length, showDocumentScannerData]);

    React.useEffect(() => {
        const loadData = async () => {
            if (customersAfterScan.data.length === 1 && customersAfterScan.typeOfScan === 1) {
                //nadjen gost po documentId-u
                setCustomer(customersAfterScan.data[0]);
                checkValidationInputs(customersAfterScan.data[0]);
            } else if (customersAfterScan.typeOfScan === 3) {
                //ne postoji gost znaci da je novi
                setCustomer(customersAfterScan.customer);
                checkValidationInputs(customersAfterScan.customer);
            }
        };

        loadData().catch((err) => {
            console.log(err);
        });
        // eslint-disable-next-line
    }, [customersAfterScan]);

    //// - override on props for custom swap logic....
    if (swapCustomer === undefined) {
        swapCustomer = async (newCustomerId: number, oldCustomerId: number) => {
            if (props.callback) {
                //fetch customer
                const newCustomer = await rest(`/Customer/${newCustomerId}`);
                props.callback(newCustomer);
            } else {
                if (history) {
                    // showCustomerFormModal(newCustomerId)
                    // history.push(`/customers/${newCustomerId}/edit`);
                }
            }
            closeModal();
        };
    }

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const _customer: CustomerModel = { ...customer };
        //@ts-ignore
        _customer[e.target.name as keyof CustomerModel] = e.target.value;

        if (e.target.name === 'documentId') {
            clearTimeout(checkIfDocumentIdIsUniqueDebouncer);
            checkIfDocumentIdIsUniqueDebouncer = setTimeout(() => {
                checkIfDocumentIdIsUnique();
            }, 1000);
        }

        if (e.target.name === 'loyaltyProgramId') {
            _customer['loyaltyProgramId'] = e.target.value ? Number(e.target.value) : null;
        }

        setCustomer({ ..._customer }); //spread to rerender
        if (e.target.name === 'firstName' || e.target.name === 'lastName' || e.target.name === 'email') {
            checkValidationInputs(_customer);
        }

        if (
            e.target.name === 'birthCountry' ||
            e.target.name === 'nationality' ||
            e.target.name === 'country' ||
            e.target.name === 'documentId' ||
            e.target.name === 'documentType'
        ) {
            let validationResults = secondValidationInputs(_customer);
            markInvalidInputRefs(validationResults, true);
        }
    };

    const handleLanguageChange = (e: { label: string; value: string }, type: any) => {
        const _customer: CustomerModel = { ...customer };
        if (type.name === 'preferredLanguageSelect') {
            _customer['preferredLanguage'] = e.value;
        } else {
            _customer['otherLanguage'] = e.value;
        }
        setCustomer({ ..._customer }); //spread to rerender
    };
    const checkIfDocumentIdIsUnique = async () => {
        if (customer.documentId) {
            const existingCustomersWitSameDocumentId: any[] = [];
            // await rest('/checkIfCustomerDocumentIdIsUnique', 'POST', {
            //     documentId: customer.documentId,
            // });
            if (existingCustomersWitSameDocumentId.length > 0) {
                //ako je edit customera te se rezultat ove funkcije poklapa sa editiranim customerom, ignoriraj
                const conflictingCId = existingCustomersWitSameDocumentId[0].id;
                if (conflictingCId !== Number(customerId)) {
                    setConflictingCustomerId(conflictingCId);
                } else {
                    setConflictingCustomerId(null);
                }
            } else {
                setConflictingCustomerId(null);
            }
        } else {
            setConflictingCustomerId(null);
        }
    };

    const checkValidationInputs = (objToValidate: CustomerModel) => {
        let validationResults = getValidationInputs(objToValidate);
        markInvalidInputRefs(validationResults);

        let secondValidationResults = secondValidationInputs(objToValidate);
        markInvalidInputRefs(secondValidationResults, true);
    };

    const getValidationInputs = (data: CustomerModel) => {
        let invalidInputs = [];
        let validInputs = [];
        if (!data.firstName || data.firstName === '') {
            invalidInputs.push(firstNameRef);
        } else {
            validInputs.push(firstNameRef);
        }
        if (!data.lastName || data.lastName === '') {
            invalidInputs.push(lastNameRef);
        } else {
            validInputs.push(lastNameRef);
        }

        if (data.email && data.email !== null && data.email.trim() !== '') {
            /* eslint-disable-next-line */
            const re =
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if (!re.test(String(data.email).toLowerCase())) {
                invalidInputs.push(emailRef);
            } else {
                validInputs.push(emailRef);
            }
        } else {
            validInputs.push(emailRef);
        }

        return { invalidInputs, validInputs };
    };

    const secondValidationInputs = (data: CustomerModel) => {
        let invalidInputs = [],
            validInputs = [];
        if (!data.documentId || data.documentId === '') {
            invalidInputs.push(documentIdRef);
        } else {
            validInputs.push(documentIdRef);
        }

        if (!data.documentType || data.documentType === '') {
            invalidInputs.push(documentTypeRef);
        } else {
            validInputs.push(documentTypeRef);
        }

        if (!data.dateOfBirth || data.dateOfBirth === undefined || data.dateOfBirth === null) {
            invalidInputs.push(birthDateRef);
        } else {
            validInputs.push(birthDateRef);
        }

        if (!data.birthCountry || data.birthCountry === '') {
            invalidInputs.push(birthCountryRef);
        } else {
            validInputs.push(birthCountryRef);
        }

        if (!data.gender || data.gender === '') {
            invalidInputs.push(genderRef);
        } else {
            validInputs.push(genderRef);
        }

        if (!data.nationality || data.nationality === '') {
            invalidInputs.push(citizenshipRef);
        } else {
            validInputs.push(citizenshipRef);
        }

        if (!data.country || data.country === '') {
            invalidInputs.push(countryRef);
        } else {
            validInputs.push(countryRef);
        }

        return { invalidInputs, validInputs };
    };

    const markInvalidInputRefs = (validationResults: any, warning?: boolean) => {
        //generic
        const { invalidInputs, validInputs } = validationResults;
        invalidInputs.forEach((input: any) => {
            if (input && input.current) {
                let classes = input.current.className.split(' ');
                if (!_.includes(classes, 'is-invalid') && !warning) {
                    classes.push(' is-invalid');
                }
                if (!_.includes(classes, 'is-invalid') && warning) {
                    classes.push(' is-invalid-warning');
                }
                input.current.className = classes.join(' ');
            }
        });
        validInputs.forEach((input: any) => {
            if (input && input.current) {
                let classes = input.current.className.split(' ');
                if (_.includes(classes, 'is-invalid')) {
                    _.remove(classes, (i: any) => i === 'is-invalid');
                }
                if (_.includes(classes, 'is-invalid-warning')) {
                    _.remove(classes, (i: any) => i === 'is-invalid-warning');
                }
                input.current.className = classes.join(' ');
            }
        });
    };

    const saveFormData = async () => {
        const validationRefs = getValidationInputs(customer);
        markInvalidInputRefs(validationRefs);
        await checkIfDocumentIdIsUnique();

        const secondValidationRefs = secondValidationInputs(customer);
        markInvalidInputRefs(secondValidationRefs, true);

        try {
            if (conflictingCustomerId === null && validationRefs.invalidInputs.length === 0) {
                //only if document id is unique....
                customer.params = JSON.stringify(customer.params);
                const trimmedName = customer && customer.firstName ? customer.firstName.replace(/\s+$/, '') : '';
                const trimmedSurname = customer && customer.lastName ? customer.lastName.replace(/\s+$/, '') : '';
                customer.firstName = trimmedName;
                customer.lastName = trimmedSurname;
                customer.companyId = company ? company.id : null;
                const res = await rest('/createOrUpdateCustomer', 'POST', customer);
                if (!customer.id) {
                    toast(translate('Customer created!'), {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        type: toast.TYPE.SUCCESS,
                    });
                }
                // await saveModelData('Customer', customer);
                if (props.callback) {
                    props.callback(res);
                } else {
                    if (history) {
                        history.push('/customers');
                    }
                }
                closeModal();
            }
        } catch (err: any) {
            console.warn(err);
            toast(err.message, {
                position: toast.POSITION.BOTTOM_RIGHT,
                type: toast.TYPE.ERROR,
            });
            closeModal();
        }
    };

    const cancelFormHandler = async () => {
        if (cancelHandler) {
            cancelHandler();
        } else if (history) {
            history.push('/customers');
        }
        closeModal();
    };

    if (loading) {
        return translate('Loading ...');
    }

    const refInputs = {
        firstNameRef,
        lastNameRef,
        emailRef,
        birthDateRef,
        birthCountryRef,
        citizenshipRef,
        countryRef,
        genderRef,
    };

    const customerFormInputs = (
        <div>
            <FormInputs
                customer={customer}
                company={company}
                setCustomer={setCustomer}
                handleInputChange={handleInputChange}
                handleLanguageChange={handleLanguageChange}
                setCompany={setCompany}
                refInputs={refInputs}
                checkValidationInputs={checkValidationInputs}
            />

            <div className="flex-center-center mt-20p">
                <ConfirmButtons onConfirm={saveFormData} onCancel={cancelFormHandler} confirmText={'Save'} />
            </div>
        </div>
    );

    return (
        <div className="">
            {customHeader || customHeader === '' || !customHeader ? customHeader : null}

            <div className="display-flex mt-4p mb-16p">
                <div
                    className="ml-4p button-info pointer"
                    onClick={(e) => {
                        e.preventDefault();
                        setCustomer(props.customer ? props.customer : ({ params: {} } as CustomerModel));
                        setCustomersAfterScan({
                            data: [],
                            typeOfScan: 0,
                            visaInfo: null,
                            customer: {} as CustomerModel,
                        });
                        setShowDocumentScannerData(!showDocumentScannerData);
                    }}
                >
                    {translate('READ FROM SCANNER')}
                </div>
            </div>

            <div className="">
                {showDocumentScannerData ||
                (customersAfterScan?.data?.length > 0 &&
                    (customersAfterScan.typeOfScan === 2 || customersAfterScan.typeOfScan === 1)) ? null : (
                    <div className="display-flex">
                        <div className="mr-8p flex">
                            <label className="text-bold ml-4p" htmlFor="">
                                {translate('Personal document id')}
                            </label>
                            <input
                                ref={documentIdRef}
                                autoComplete="off"
                                placeholder={translate('1257524151')}
                                name="documentId"
                                value={customer.documentId ? customer.documentId : ''}
                                onChange={handleInputChange}
                                type="text"
                                className="input input-modal"
                                onBlur={checkIfDocumentIdIsUnique}
                            />
                        </div>
                        <div className="ml-8p flex">
                            <label className="text-bold ml-4p" htmlFor="">
                                {translate('Document type')}
                            </label>
                            <select
                                ref={documentTypeRef}
                                name="documentType"
                                className="input input-modal"
                                value={customer.documentType ? customer.documentType : ''}
                                onChange={(e: any) => {
                                    handleInputChange(e);
                                }}
                            >
                                <option value={0}>{translate('Nothing selected')}</option>
                                {otherSettings?.csvDownloadTemplate === 'eVisitor' ? (
                                    documentTypesEVisitor?.map((t, key) => {
                                        return (
                                            <option key={key} value={t.Code}>
                                                {t.Name}
                                            </option>
                                        );
                                    })
                                ) : (
                                    <>
                                        <option value={'P'}>{translate('Passport')}</option>
                                        <option value={'I'}>{translate('Identity card')}</option>
                                        <option value={'V'}>{translate('Visa')}</option>
                                        <option value={'D'}>{translate('Driving licence')}</option>
                                        <option value={'X'}>{translate('Other')}</option>
                                    </>
                                )}
                            </select>
                        </div>
                    </div>
                )}

                {conflictingCustomerId ? (
                    <ConflictingCustomerAction
                        swapCustomer={swapCustomer}
                        customer={customer}
                        conflictingCustomerId={conflictingCustomerId}
                    />
                ) : showDocumentScannerData ? (
                    <DocumentScanner
                        docScan={docScan}
                        customer={null}
                        reservation={reservation}
                        setCustomersAfterScan={setCustomersAfterScan}
                        setShowDocumentScannerData={setShowDocumentScannerData}
                    />
                ) : customersAfterScan.data.length > 0 &&
                  (customersAfterScan.typeOfScan === 2 || customersAfterScan.typeOfScan === 1) ? (
                    <CustomersListAfterScann
                        customersAfterScan={customersAfterScan}
                        setCustomer={setCustomer}
                        setCustomersAfterScan={setCustomersAfterScan}
                    />
                ) : (
                    customerFormInputs
                )}
            </div>
            {customersAfterScan?.data?.length > 0 &&
            (customersAfterScan.typeOfScan === 2 || customersAfterScan.typeOfScan === 1) ? (
                <div className="display-flex justify-content-center">
                    <div
                        className="button-primary p-1 m-2 pointer"
                        onClick={() => {
                            if (customersAfterScan?.customer?.id) {
                                delete customersAfterScan.customer.id;
                            }
                            setCustomer(customersAfterScan.customer);
                            setCustomersAfterScan({ ...customersAfterScan, data: [] });
                        }}
                    >
                        {translate('ADD NEW')}
                    </div>
                </div>
            ) : null}
        </div>
    );
};

interface ConflictingCustomerActionProps {
    swapCustomer: any;
    customer: CustomerModel;
    conflictingCustomerId: number | null;
}

const ConflictingCustomerAction = (props: ConflictingCustomerActionProps) => {
    const { swapCustomer, customer, conflictingCustomerId } = props;
    return (
        <div className="text-left">
            U bazi već postoji gost sa ovim brojem dokumenta, molim da promjenite unos ili učitajte postojećeg gosta!
            {translate('customerDocumentIdConflictMessage')}
            <hr />
            {swapCustomer ? (
                <div
                    onClick={(e) => {
                        e.preventDefault();
                        swapCustomer(conflictingCustomerId, customer.id);
                    }}
                    className="button-warning"
                >
                    {translate('LOAD EXISTING DATA')}
                </div>
            ) : null}
        </div>
    );
};

export default CustomerForm;
