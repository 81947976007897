export const overrides = {
    en: {
        hospital: {
            Guest: 'Client',
            'Guest informations': 'Client informations',
            'New Guest': 'New Client',
            'Guest arrival': 'Client arrival',
            'Guest/Res': 'Client/Res',
            'Guest data': 'Client data',
            'Guest book': 'Client book',
            'Guest list': 'Client list',
            'Guest = the occupancy of the room': 'Client = the occupancy of the room',
            'Guest info': 'Client info',
            'Guest ': 'Client ',
            'Allow guest access, only from rooms with tags': 'Allow client access, only from rooms with tags',
            Guests: 'Clients',
            GUEST: 'CLIENT',
            'GUEST PHONE': 'CLIENT PHONE',
            'Print number of guests': 'Print number of clients',
            'Incoming guest at': 'Incoming client at',
            'Guests informations': 'Clients informations',
            reservationsPanel_clientsBook: 'Clients book',
            reservationsPanel_clientsBookForEditing: 'Clients book by report for editing',
            'Number of days for encripting guests': 'Number of days for encripting clients',
            'All guests': 'All clients',
            'Domestic guests': 'Domestic clients',
            'Foreign guests': 'Foreign clients',
            'Guests book report': 'Clients book report',
            'Registered guests report': 'Registered clients report',
            'Registered guests': 'Registered clients',
            'Guests book': 'Clients book',
            'View guests list': 'View clients list',
            'Checked in guests': 'Checked in clients',
            'Checked out guests': 'Checked out clients',
            'reservation guest': 'reservation client',
            'The guests should have been checked out': 'The clients should have been checked out',
            'The guests should have been checked in': 'The clients should have been checked in',
            'The arrival of guests is today': 'The arrival of clients is today',
            'The departure of guests is today': 'The departure of clients is today',
            'Send reservation confirmation to guest': 'Send reservation confirmation to client',
            'Please select one of the following guests first, then scann his document data.':
                'Please select one of the following clients first, then scan his document data.',
            'Other guests': 'Other clients',
            'There is a few similar guests. Click on card that match with your data or create new with scanned data if no data match.':
                'There is a few similar clients. Click on card that matches with your data or create new with scanned data if no data match.',
            'Only registered guests': 'Only registered clients',
            'Show registered guests': 'Show registered clients',
            'Number of guests': 'Number of clients',
            'Select check in and check out date for guest ': 'Select check in and check out date for client ',
            "The guest's stay": "The client's stay",
            "The guest's stay does not matches with the duration of the reservation":
                "The client's stay does not match the duration of the reservation",
            "The guest's stay does not match the duration of the reservation":
                "The client's stay does not match the duration of the reservation",
            guest1CardCode: 'Client 1',
            guest2CardCode: 'Client 2',
            guest3CardCode: 'Client 3',
            guest1CardCodeValidTo: 'Client 1 card valid until',
            guest2CardCodeValidTo: 'Client 2 card valid until',
            guest3CardCodeValidTo: 'Client 3 card valid until',
            'rooms / guests incoming': 'rooms / clients incoming',
            'rooms / guests outgoing': 'rooms / clients outgoing',
            'guests currently checked in': 'clients currently checked in',
            'Register guests on eVisitor': 'Register clients on eVisitor',
            'Show guests book by reports': 'Show clients book by reports',
            'Show guests book list': 'Show clients book list',
            'Reservations by guests book report': 'Reservations by clients book report',
            'Guests book by closed reports': 'Clients book by closed reports',
            'Guests book list': 'Clients book list',
            'Guests book by report for editing': 'Clients book by report for editing',
            'Mark guests data as send to portal': 'Mark clients data as sent to portal',
            'Mark guests data': 'Mark clients data',
            'Allow guest access for rooms with tags': 'Allow client access for rooms with tags',
            'Scanning guest documents': 'Scanning client documents',
            'Select guest': 'Select client',
            'Scan the document using the device located next to the computer for guest ':
                'Scan the document using the device located next to the computer for client ',
            'Select the correct guest data': 'Select the correct client data',
            ' guests with similar information. Select the appropriate option or create a new one.':
                ' clients with similar information. Select the appropriate option or create a new one.',
            'Create a guest with the following information': 'Create a client with the following information',
            'Show guests': 'Show clients',
            'Guests number': 'Clients number',
            'If checked, the guest card is programmed to all controllers at once.':
                'If checked, the client card is programmed to all controllers at once.',
                
            'Add Customer': 'Add Client',
            'Edit customer': 'Edit client data',
            'Customer has ': 'Client has ',
            'SEARCH FOR CUSTOMER (by first and last name, email, document id)':
                'SEARCH FOR CLIENT (by first and last name, email, document id)',
            'Customers data reports': 'Clients data reports',
            Customers: 'Clients',
            'Browse and create customers and companies required by reservation system.':
                'Browse and create clients and companies required by reservation system.',
            'Customer/Company was deleted': 'Client/Company was deleted',
            'Search customer or company name, reservation id or room name':
                'Search client or company name, reservation id or room name',
            CUSTOMERS: 'CLIENTS',
            'SEARCH CUSTOMERS': 'SEARCH CLIENTS',
            'Customer Name': 'Client Name',
            'CUSTOMERS DATA REPORT': 'CLIENTS DATA REPORT',
            customerDocumentIdConflictMessage:
            'There is another entry with this document id. Please, change this field or load existing client data.',
            reservationsPanel_customerReports: 'Clients data reports',
            foldersPanel_customers: 'Clients',
            'Search for a customer or company name': 'Search for a client or company name',
            'Please, select customer or company!': 'Please, select client or company!',
            'Customer created!': 'Client created!',
            'Update customer': 'Update client',
            'Do you want to update the customer with new document id?':
                'Do you want to update the client with new document id?',
            'Customer && Company': 'Client && Company',
            'Customer(s) edited!': 'Client(s) edited!',
            'Customers data report': 'Clients data report',
            'Search for customer (by first and last name)': 'Search for client (by first and last name)',
            'Create customer': 'Create client',
            customerUuid: 'Client uuid',
            'NEW HOTEL': 'NEW HEALTHCARE FACILITY',
            gsettings_hotelName: 'Healthcare facility name',
            'Welcome to our hotel!': 'Welcome to our healthcare facility!',
            'Hotel name': 'Healthcare facility name',
            'Hotel guests': 'Healthcare clients',
            posBartender: 'Pos staff member',

        },
    },
    hr: {
        hospital: {
            Guest: 'Klijent',
            'Guest informations': 'Informacije o klijentu',
            'New Guest': 'Dodaj klijenta',
            'Guest arrival': 'Dolazak klijenta',
            'Guest/Res': 'Klijent/Res',
            'Guest data': 'Podaci o klijentu',
            'Guest book': 'Knjiga klijenata',
            'Guest list': 'Popis klijenata',
            'Guest info': 'Klijenti info.',
            'Guest ': 'Klijent ',
            'Allow guest access, only from rooms with tags': 'Dozvoli pristup klijentu, samo iz soba s oznakama',
            Guests: 'Klijenti',
            GUEST: 'KLIJENT',
            'GUEST PHONE': 'Broj telefona klijenta',
            'Print number of guests': 'Ispiši broj klijenata',
            'Incoming guest at': 'Klijent dolazi',
            'Guests informations': 'Informacije o klijentima',
            reservationsPanel_guestsBook: 'Knjiga klijenata',
            reservationsPanel_registeredGuests: 'Prijavljeni klijenti',
            'Number of days for encripting guests': 'Broj dana za enkriptiranje klijenta',
            'All guests': 'Svi klijenti',
            'Domestic guests': 'Domaći klijenti',
            'Foreign guests': 'Strani klijenti',
            'Guests book report': 'Knjiga klijenata',
            'Registered guests report': 'Izvještaj o prijavljenim klijentima',
            'Registered guests': 'Prijavljeni klijenti',
            'Guests book': 'Knjiga klijenata',
            'View guests list': 'Pogledaj listu klijenata',
            'Checked in guests': 'Klijenti za prijavu',
            'Checked out guests': 'Klijenti za odjavu',
            'reservation guest': 'klijenta s rezervacije',
            'The guests should have been checked out': 'Klijenti su trebali biti odjavljeni',
            'The guests should have been checked in': 'Klijenti su trebali biti prijavljeni',
            'The arrival of guests is today': 'Dolazak klijenata je danas',
            'The departure of guests is today': 'Odlazak klijenata je danas',
            'Send reservation confirmation to guest': 'Pošalji potvrdu rezervacije klijentu',
            'Please select one of the following guests first, then scann his document data.':
                'Molimo odaberite jednog od sljedećih klijenata, zatim skenirajte njegov dokument.',
            'Other guests': 'Ostali klijenti',
            'There is a few similar guests. Click on card that match with your data or create new with scanned data if no data match.':
                'Postoje klijenti sa sličnim podacima. Kliknite na karticu koja odgovara skeniranim podacima ili kreirajte novog ako se podaci ne podudaraju.',
            'Only registered guests': 'Samo prijavljeni klijenti',
            'Show registered guests': 'Prikaži prijavljene klijente',
            'Number of guests': 'Broj klijenata',
            'Guest = the occupancy of the room': 'Klijenti = zauzetost sobe',
            'Select check in and check out date for guest ': 'Odaberite vrijeme prijave i odjave za klijenta ',
            "The guest's stay": 'Boravak klijenta',
            "The guest's stay does not matches with the duration of the reservation":
                'Boravak klijenta ne podudara se s trajanjem rezervacije',
            "The guest's stay does not match the duration of the reservation":
                'Boravak klijenta ne podudara se s trajanjem rezervacije',
            guest1CardCode: 'Klijent 1',
            guest2CardCode: 'Klijent 2',
            guest3CardCode: 'Klijent 3',
            guest1CardCodeValidTo: 'Kartica klijenta 1 vrijedi do',
            guest2CardCodeValidTo: 'Kartica klijenta 2 vrijedi do',
            guest3CardCodeValidTo: 'Kartica klijenta 3 vrijedi do',
            'rooms / guests incoming': 'sobe / klijenti nadolazeći',
            'rooms / guests outgoing': 'sobe / klijenti na odlasku',
            'guests currently checked in': 'trenutno prijavljenih klijenata',
            'Register guests on eVisitor': 'Prijavi klijente na eVisitor',
            'Show guests book by reports': 'Prikaži knjigu klijenata po zaključcima',
            'Show guests book list': 'Prikaži listu knjige klijenata',
            'Reservations by guests book report': 'Rezervacije iz knjige klijenata po zaključku',
            'Guests book by closed reports': 'Knjiga klijenata po zaključcima',
            'Guests book list': 'Lista knjige klijenata',
            'Guests book by report for editing': 'Knjiga klijenata po zaključku za uređivanje',
            'Mark guests data as send to portal': 'Označi klijente čiji su podaci poslani na portal',
            'Mark guests data': 'Označi klijente',
            'Allow guest access for rooms with tags': 'Dopustite klijentima pristup sobama s oznakama',
            'Scanning guest documents': 'Skeniranje dokumenata klijenata',
            'Select guest': 'Odaberite klijenta',
            'Scan the document using the device located next to the computer for guest ':
                'Skenirajte dokument pomoću uređaja koji se nalazi pored računala za klijenta ',
            'Select the correct guest data': 'Odaberite ispravne podatke klijenta',
            ' guests with similar information. Select the appropriate option or create a new one.':
                ' klijenata sa sličnim podacima. Odaberite odgovarajuću opciju ili kreirajte novu',
            'Create a guest with the following information': 'Kreirajte klijenta sa sljedećim podacima',
            'Show guests': 'Prikaži klijente',
            'Guests number': 'Broj klijenata',
            'If checked, the guest card is programmed to all controllers at once.':
                'Ako je označeno, kartica klijenta se programira na sve kontrolere odjednom.',

            'Add Customer': 'Dodaj klijenta',
            'Edit customer': 'Uredi podatke o klijentu',
            'Customer has ': 'Klijent ima ',
            'SEARCH FOR CUSTOMER (by first and last name, email, document id)':
                'TRAŽI KLIJENTA (po imenu i prezimenu, email-u, oznaki dokumenta)',
            'Customers data reports': 'Izvješća o klijentima',
            Customers: 'Klijenti',
            'Browse and create customers and companies required by reservation system.':
                'Pregledajte i dodajte klijente i tvrtke koje zahtjeva rezervacijski sustav.',
            'Customer/Company was deleted': 'Klijent/Tvrtka je izbrisan(a)',
            'Search customer or company name, reservation id or room name':
                'Traži ime klijenta ili tvrtke, id rezervacije ili naziv sobe',
            CUSTOMERS: 'KLIJENTI',
            'SEARCH CUSTOMERS': 'TRAŽI KLIJENTA',
            'Customer Name': 'Ime klijenta',
            'CUSTOMERS DATA REPORT': 'IZVJEŠĆA O KLIJENTIMA',
            customerDocumentIdConflictMessage:
                'U bazi već postoji KLIJENT sa ovim brojem dokumenta, molim da promjenite unos!',
            reservationsPanel_customerReports: 'Pregled izvješća o klijentima',
            foldersPanel_customers: 'Klijenti',
            'Search for a customer or company name': 'Traži klijenta ili tvrtku',
            'Please, select customer or company!': 'Molimo odaberite klijenta ili tvrtku!',
            'Customer created!': 'Klijent kreiran!',
            'Update customer': 'Ažuriraj klijenta',
            'Do you want to update the customer with new document id?':
                'Želite li ažurirati klijenta s novim dokument id-om?',
            'Customer && Company': 'Klijent i Tvrtka',
            'Customer(s) edited!': 'Klijent(i) uređeni!',
            'Customers data report': 'Izvješće o klijentima',
            'Search for customer (by first and last name)': 'Traži klijenta (po imenu i prezimenu)',
            'Create customer': 'Kreiraj klijenta',
            customerUuid: 'Uuid klijenta',
            'NEW HOTEL': 'NOVI ZDRAVSTVENI OBJEKT',
            gsettings_hotelName: 'Naziv zdravstvenog objekta',
            'Welcome to our hotel!': 'Dobrodošli u naš ustanovu!',
            'Hotel name': 'Naziv ustanove',
            'Hotel guests': 'Klijenti ustanove',
            posBartender: 'Pos djelatnik',

        },
    },
};
